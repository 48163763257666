<template>
  <div class="container-fluid mb-3">
    <b-table
      :busy="isBusy"
      :fields="fields"
      @row-clicked="triggerModal"
      :selectable="true"
      striped
      hover
      :items="items"
      show-empty
    >
      <template #cell(last_message)="row">
        <p>
          {{
            row.item.message == "xyz"
              ? "Viewing Request"
              : limitCharacters(row.item.message)
          }}
        </p>
      </template>
      <template #empty>
        <div v-if="fromBuying" class="row">
          <div class="col-md-8"></div>
          <div class="col-md-4 d-flex justify-content-center">
            <button
              class="btn btn-primary listing-button-color"
              @click="showModal"
            >
              Book Now
            </button>
          </div>
        </div>
        <div v-else class="d-flex justify-content-center">
          <p>No Messages</p>
        </div>
      </template>
      <template #cell(viewing)>
        <p v-if="checkIfUserHasViewing()">
          {{ changeFormat(checkIfUserHasViewing().slotDate) }}
        </p>
        <button
          class="btn btn-primary listing-button-color"
          @click="showModal"
          v-else
        >
          Book Now
        </button>
      </template>
      <template #cell(from)="row">
        <p>{{ row.item.user.user_name }}</p>
      </template>
      <template #cell(buyer)="row">
        <p>{{ row.item.user.user_name }}</p>
      </template>
      <template #cell(status)="row">
        <p>{{ capitalize(row.item.status) }}</p>
      </template>
      <template #cell(date)="row">
        <p>{{ changeFormat(row.item.createdAt) }}</p>
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
    <b-modal
      hide-footer
      centered
      ref="model-1"
      scrollable
      size="lg"
      id="modal-1"
      @shown="shown()"
      title="Enquire"
    >
      <div v-if="fromBuying">
      </div>
      <div class="inner-pages ui-elements hd-white">
        <div class="tabbed-content button-tabs">
          <ul class="tabs">
            <li :class="enquireType == 'question' ? 'active' : ''">
              <div @click="enquireType == 'question'" class="tab-title">
                <span>Ask a question</span>
              </div>
            </li>
            <li :class="enquireType == 'viewing' ? 'active' : ''" v-show="fromBuying">
              <div @click="enquireType == 'viewing'" class="tab-title">
                <span>Propose a Viewing</span>
              </div>
            </li>
            <li :class="enquireType == 'offer' ? 'active' : ''" v-show="fromBuying">
              <div @click="enquireType == 'offer'" class="tab-title">
                <span>Make an Offer</span>
              </div>
            </li>
            <span class="float-right">
              <div  class="for-display"
                id="blocking"
                style="padding: 13px;
             cursor: pointer;
    display: inline-block;
    text-align: center;
    border-radius: 9px;
    width: auto;
    color: white;"
              >
                <i class="fa fa-ellipsis-v mr-1 "></i>
              </div>
            </span>
          </ul>
          <ul class="res-bloc-popup"
            style="cursor: pointer; display:none; text-align: center;
    width: 23%;
    border: 1px solid #658C59;
    border-radius: 8px;
    line-height: 32px;
   
    position: absolute;
    background: white;
    left: 65%;
    top: 6%;"
            id="dfone"
          >
            <li
              v-if="!amIBlocked"
              @click="isUserBlocked ? unBlockUser() : blockUser()"
              style=" border-bottom: 1px solid #658C59;"
            >
              {{ isUserBlocked ? "Unb" : "B" }}lock User
            </li>
            <li v-else style=" border-bottom: 1px solid #658C59;">
              You are blocked
            </li>
            <li @click="reportUser">
              Report
            </li>
          </ul>
          <ul class="content">
            <li :class="enquireType == 'question' ? 'active' : ''">
              <div class="tab-content">
                <div class="mt-3">
                  <Chat
        ref="chat"
        :participants="participants"
        :myself="myself"
        :messages="allMessages"
        :chat-title="chatTitle"
        :placeholder="placeholder"
        :colors="colors"
        :send-images="false"
        :border-style="borderStyle"
        :hide-close-button="hideCloseButton"
        :close-button-icon-size="closeButtonIconSize"
        :submit-icon-size="submitIconSize"
        :async-mode="asyncMode"
        :scroll-bottom="scrollBottom"
        :display-header="false"
        :profile-picture-config="profilePictureConfig"
        :timestamp-config="timestampConfig"
        @onMessageSubmit="onMessageSubmit"
        @onType="onType"
        @onClose="onClose"
      >
        <template v-slot:header>
          <div>
            <p
              v-for="(participant, index) in participants"
              :key="index"
              class="custom-title"
            >
              {{ capitalize(participant.name) }}
            </p>
          </div>
        </template>
        <template #content-myself="{ message }">
          <div v-if="message.additionalInfo ? message.additionalInfo.isViewing : false">
            <div v-if="message.additionalInfo.viewingObject.status == 'accept'">
              <p>The viewing</p>
            <p style="line-height: 1px;">On {{ convertDate(message.additionalInfo.viewingObject.slotDate) }}</p>
            <p style="line-height: 1px;">At {{ message.additionalInfo.viewingObject.slotTime }}</p>
            <p>Has been accepted.</p>
            </div>
            <div
                v-else-if="
                  message.additionalInfo.viewingObject.status == 'pending' && fromBuying
                "
              >
                <p>I would like to view this property</p>
                <p style="line-height: 1px">On {{convertDate(message.additionalInfo.viewingObject.slotDate)}}</p>
                <p style="line-height: 1px">At {{ message.additionalInfo.viewingObject.slotTime }}</p>
              </div>
            <div v-else-if="message.additionalInfo.viewingObject.status == 'suggest'">
            <p>I can offer a viewing</p>
                <p style="line-height: 1px">On {{convertDate(message.additionalInfo.viewingObject.slotDate)}}</p>
                <p style="line-height: 1px">At {{ message.additionalInfo.viewingObject.slotTime }}</p>
            </div>
            <div v-else-if="message.additionalInfo.viewingObject.status == 'reject'">
              <p>The viewing</p>
            <p style="line-height: 1px;">On {{ convertDate(message.additionalInfo.viewingObject.slotDate) }}</p>
            <p style="line-height: 1px;">At {{ message.additionalInfo.viewingObject.slotTime }}</p>
            <p>Has been rejected, you can propose another time.</p>
            </div>
          </div>
          <p style="line-height: 15px;" v-else>{{ message.content }}</p>
        </template>
        <template #content-participant="{ message }">
          <div v-if="message.additionalInfo.isViewing">
            <div v-if="message.additionalInfo.viewingObject.status == 'accept'">
              <p>The viewing</p>
            <p style="line-height: 1px;">On {{ convertDate(message.additionalInfo.viewingObject.slotDate) }}</p>
            <p style="line-height: 1px;">At {{ message.additionalInfo.viewingObject.slotTime }}</p>
            <p>Has been accepted.</p>
            </div>
            <div v-else-if="message.additionalInfo.viewingObject.status == 'pending'">
              <p>Has requested a viewing</p>
            <p style="line-height: 1px;">On {{ convertDate(message.additionalInfo.viewingObject.slotDate) }}</p>
            <p style="line-height: 1px;">At {{ message.additionalInfo.viewingObject.slotTime }}</p>
            <div>
              <button @click="acceptBooking(message.additionalInfo)" class="btn btn-primary mr-2" style="background: #3e4452; border-color: #3e4452">Accept</button>
              <button @click="rejectBooking(message.additionalInfo)" class="btn btn-primary mr-2" style="background: #3e4452; border-color: #3e4452">Reject</button>
              <button @click="suggestBooking(message.additionalInfo)" class="btn btn-primary" style="background: #3e4452; border-color: #3e4452">Suggest</button>
            </div>
            </div>
            <div v-else-if="message.additionalInfo.viewingObject.status == 'reject'">
              <p>The viewing</p>
            <p style="line-height: 1px;">On {{ convertDate(message.additionalInfo.viewingObject.slotDate) }}</p>
            <p style="line-height: 1px;">At {{ message.additionalInfo.viewingObject.slotTime }}</p>
            <p>Has been rejected.</p>
            </div>
            <div v-else-if="message.additionalInfo.viewingObject.status == 'suggest'">
              <p>Has suggested a viewing</p>
            <p style="line-height: 1px;">On {{ convertDate(message.additionalInfo.viewingObject.slotDate) }}</p>
            <p style="line-height: 1px;">At {{ message.additionalInfo.viewingObject.slotTime }}</p>
            <div>
              <button @click="acceptBooking(message.additionalInfo)" class="btn btn-primary mr-2" style="background: #3e4452; border-color: #3e4452">Accept</button>
              <button @click="rejectBooking(message.additionalInfo)" class="btn btn-primary mr-2" style="background: #3e4452; border-color: #3e4452">Reject</button>
              <button @click="suggestBooking(message.additionalInfo)" class="btn btn-primary" style="background: #3e4452; border-color: #3e4452">Suggest</button>
            </div>
            </div>
          </div>
          <p style="line-height: 15px;" v-else>{{ message.content }}</p>
          <div :class="`message-id-${message.additionalInfo.id}`" v-if="shouldSuggest[message.additionalInfo.id]">
            <p class="mb-2">Date (e.g. 20/07/2021)</p>
            <span style="margin-top: -8px" class="d-flex">
              <input
                @input="addSlashes($event)"
                style="overflow: auto; height: 31px"
                v-model="date"
                type="text"
                name=""
                id=""
              />
            </span>
            <p style="margin-top: -13px;" class="error mb-0" v-if="$v.date.$dirty && !$v.date.isDateValid">Date is invalid!</p>
            <p style="line-height: 5px">Time (e.g. 12 pm)</p>
            <span class="d-flex">
              <input
                style="overflow: auto; height: 31px;margin-bottom:10px;"
                v-model="time"
                type="text"
                name=""
                id=""
              />
            </span>
            <p style="margin-top: -11px; margin-bottom:0px" class="error" v-if="$v.time.$dirty && !$v.time.isTimeValid">Time is invalid!</p>
            <button @click="updateViewing(message.additionalInfo)" class="btn btn-primary mt-2" style="background: #3e4452; border-color: #3e4452; padding-left: 25px; padding-right: 25px; margin-left:-5px">Send</button>
          </div>
        </template>
      </Chat>
                </div>
              </div>
            </li>
            <li :class="enquireType == 'viewing' ? 'active' : ''">
              <div class="tab-content">
                <div class="row">
                  <div class="col-md-12">
                    <p style="margin-bottom: 30px;">
                      Like this property? Why not take a look at it.
                      <br />
                      <br />
                      You can propose a date and time for viewing. This will
                      send a message to your seller informing them that you are
                      interested.
                    </p>
                  </div>

                  <div class="col-md-6">
                    <p style="line-height: 5px">Date (e.g. 20/07/2021)</p>
                  
                      <input
                        @input="addSlashes($event)"
                        style="overflow: auto;"
                        v-model="date"
                        type="text"
                        name=""
                        id=""
                      />
                  
                    <p
                      class="error"
                      v-if="$v.date.$dirty && !$v.date.isDateValid"
                    >
                      Date is invalid!
                    </p>
                    <button  style="margin-top:4px;    width: 268px; background-color: #595959;
    color: white;" class="btn btn-yellow res-bb res-full-width for-display"> Cancel </button>
                  </div>
                  <div class="col-md-6 res-mt-1rem">
                    <p style="line-height: 5px">Time (e.g. 12 pm)</p>
                   
                      <input
                        style="overflow: auto;"
                        v-model="time"
                        type="text"
                        name=""
                        id=""
                      />
                   
                    <p
                      class="error"
                      v-if="$v.time.$dirty && !$v.time.isTimeValid"
                    >
                      Time is invalid!
                    </p>
                      <button  style="margin-top:4px;    width: 268px; background-color: #595959;
    color: white;" class="btn btn-yellow res-bb res-full-width hide-for-big "> Cancel </button>
                    <button @click="bookTimeSlot" style="margin-top:4px;     width: 268px;color: white;
    background-color: #658C59" class="btn btn-yellow res-bb res-full-width"> Send </button>
                  </div>
                  </div>
                </div>
              
            </li>

            <li :class="enquireType == 'offer' ? 'active' : ''">
              <div class="tab-content">
                <div class="mt-3">
                  <div class="col-md-12 res-pl-0-pr-0">
                    <p style="margin-bottom: 30px;">
                      Like this property? Why not make an offer.
                      <br />
                      <br />
                      You can send an offer to the seller including the amount
                      you would like to offer and the type e.g. Mortgage or
                      Cash.
                      <br />
                      Asking Price:
                      <strong style="color:#658C59">
                        £ {{ formatter(property.price) }}
                      </strong>
                    </p>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <p class="m-0">Value </p>
                      <input
                        :value="offerAmount"
                        style="margin-top: 5px;"
                        @input="addCommas($event)"
                        type="text"
                        placeholder="Enter your offer"
                      />
                      <button style="    width: 255px; background-color: #595959;
    color: white;" class="btn btn-yellow res-bb res-full-width for-display"> Cancel </button>
                    </div>
                    <div class="col-md-6">
                        <p  class="m-0 res-mt-12 ">Type</p>
                      <select
                        style="    margin-bottom: 24px;margin-top: 5px;"
                        v-model="offerType"
                        name=""
                        id=""
                      >
                        <option
                          v-for="(item, index) in offerTypes"
                          :key="index"
                          :value="item.toLowerCase()"
                        >
                          {{ item }}
                        </option>
                      </select>
                      <button style="    width: 255px; background-color: #595959;
    color: white;" class="btn btn-yellow res-bb res-full-width mb-1 hide-for-big"> Cancel </button>
                     <button @click="makeOffer" style="    width: 257px; background-color: #658c59;
    color: white;" class="btn btn-yellow res-bb res-full-width"> Send </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Chat } from "vue-quick-chat-slotted";
import "vue-quick-chat-slotted/dist/vue-quick-chat.css";
import moment from "moment";
import { capitalize } from "../utils";
import { formatter } from "../utils";
import _ from "lodash";
import $ from "jquery";

export default {
  data() {
    return {
      offerTypes: [],
      offerType: "",
      offerAmount: "",
      isLastMessageFromSeller: false,
      enquireType: "question",
      time: "",
      date: "",
      duration: "",
      options: [
        { tag: `30 minutes`, value: 30 },
        { tag: `1 Hour`, value: 60 }
      ],
      shouldSuggest: {},
      messagesPerUser: [],
      messages: [],
      visible: true,
      chatTitle: "My chat title",
      placeholder: "Type a message",
      colors: {
        header: {
          bg: "#d30303",
          text: "#fff"
        },
        message: {
          myself: {
            bg: "#548f4d",
            text: "white"
          },
          others: {
            bg: "#fff",
            text: "black"
          },
          messagesDisplay: {
            bg: "#f7f3f3"
          }
        },
        submitIcon: "#548f4d",
        submitImageIcon: "#548f4d"
      },
      borderStyle: {
        topLeft: "10px",
        topRight: "10px",
        bottomLeft: "10px",
        bottomRight: "10px"
      },
      hideCloseButton: false,
      submitIconSize: 25,
      closeButtonIconSize: "20px",
      asyncMode: false,
      scrollBottom: {
        messageSent: true,
        messageReceived: true
      },
      displayHeader: true,
      profilePictureConfig: {
        others: true,
        myself: true,
        styles: {
          width: "30px",
          height: "30px",
          borderRadius: "50%"
        }
      },
      timestampConfig: {
        format: "HH:mm",
        relative: false
      },
      username: {},
      isBusy: false,
      reply: "",
      selectedMessageItem: {},
      items: [],
      fields: this.fromBuying
        ? ["buyer", {
          key: "last_message", label: "Last Message", tdClass: 'display-to-none', thClass: 'display-to-none'
        }, "date", "status", "viewing"]
        : ["buyer", {
          key: "last_message", label: "Last Message", tdClass: 'display-to-none', thClass: 'display-to-none'
        }, "date", "status"]
    };
  },
  validations: {
    date: {
      isDateValid: value => {
        if (
          new RegExp(/(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/]\d{4}/).test(
            value
          )
        ) {
          return (
            moment(
              `${value.split("/")[1]}/${value.split("/")[0]}/${
                value.split("/")[2]
              }`
            ).isValid() &&
            (moment
              .duration(
                moment(
                  `${value.split("/")[1]}/${value.split("/")[0]}/${
                    value.split("/")[2]
                  }`
                ).diff(moment())
              )
              .asHours()
              .toFixed(0) >= 0 ||
              moment
                .duration(
                  moment(
                    `${value.split("/")[1]}/${value.split("/")[0]}/${
                      value.split("/")[2]
                    }`
                  ).diff(moment())
                )
                .asHours()
                .toFixed(0) >= -24)
          );
        } else if (
          new RegExp(/(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-]\d{4}/).test(
            value
          )
        ) {
          return (
            moment(
              `${value.split("-")[1]}/${value.split("-")[0]}/${
                value.split("-")[2]
              }`
            ).isValid() &&
            (moment
              .duration(
                moment(
                  `${value.split("/")[1]}/${value.split("/")[0]}/${
                    value.split("/")[2]
                  }`
                ).diff(moment())
              )
              .asHours()
              .toFixed(0) >= 0 ||
              moment
                .duration(
                  moment(
                    `${value.split("/")[1]}/${value.split("/")[0]}/${
                      value.split("/")[2]
                    }`
                  ).diff(moment())
                )
                .asHours()
                .toFixed(0) >= -24)
          );
        } else
          return (
            moment(value).isValid() &&
            (moment
              .duration(moment(value).diff(moment()))
              .asHours()
              .toFixed(0) >= 0 ||
              moment
                .duration(moment(value).diff(moment()))
                .asHours()
                .toFixed(0) >= -24)
          );
      }
    },
    time: {
      isTimeValid: value => {
        return (
          moment(value, "h:mm A", true).isValid() ||
          moment(value, "h:mmA", true).isValid() ||
          moment(value, "h A", true).isValid() ||
          moment(value, "hA", true).isValid()
        );
      }
    }
  },
  props: ["property", "fromBuying"],
  computed: {
    profilePic() {
      try {
        let pic = JSON.parse(this.buyerSeller.profilePic);
        return _.defaultTo(_.get(pic,'[0]', false),`../assets/images/default-profile.webp`);
      } catch (err) {
        if(typeof this.buyerSeller.profilePic == 'object' && this.buyerSeller.profilePic instanceof Array){
          return _.defaultTo(_.get(this.buyerSeller.profilePic,'[0]', false), require(`../assets/images/default-profile.webp`));
        }
        else
          return _.get(this.buyerSeller, 'profilePic', require(`../assets/images/default-profile.webp`));
      }
    },
    isUserBlocked() {
      return (
        this.blockedUsers.find(
          user => user.toUser.id == this.property.sellerId
        ) ||
        this.blockedByUsers.find(
          user => user.fromUser.id == this.property.sellerId
        )
      );
    },
    amIBlocked() {
      return this.blockedByUsers.find(
        user =>
          user.fromUser.id == _.get(this.selectedMessageItem, "userId", null)
      );
    },
    ...mapGetters({
      authUser: "auth",
      blockedUsers: "getBlockedUsers",
      blockedByUsers: "getBlockedByUsers",
      buyerSeller: "getBuyerSeller"
    }),
    allMessages() {
      return this.messagesPerUser
        .filter(message =>
          this.fromBuying
            ? true
            : this.selectedMessageItem.userId == message.userId ||
              message.userId == this.authUser.buyer_seller_id
        )
        .map(message => {
          var date = new Date(message.createdAt);
          return {
            content: message.message,
            myself: message.userId == this.authUser.buyer_seller_id,
            participantId: message.userId,
            additionalInfo: message,
            customBgColor: null,
            customTextColor: null,
            timestamp: {
              year: date.getFullYear(),
              month: date.getMonth(),
              day: date.getDate(),
              hour: date.getHours(),
              minute: date.getMinutes(),
              second: date.getSeconds(),
              millisecond: date.getMilliseconds()
            },
            type: "text"
          };
        });
    },
    participants() {
      return this.messages
        .filter(message => message.user)
        .map(message => {
          return {
            name: message.user.user_name,
            id: message.userId,
            profilePicture: message.user.profilePic
              ? this.recipientPicture(message.user.profilePic)
              : require(`../assets/images/default-profile.webp`)
          };
        });
    },
    myself() {
      if (Object.keys(this.authUser).length) {
        return {
          name: "Me",
          id: this.authUser.buyer_seller_id,
          profilePicture: this.profilePic
        };
      } else return {};
    }
  },
  components: {
    Chat
  },
  methods: {
    formatter: formatter,
    capitalize: capitalize,
    async refresh(){
      this.isBusy = true;
    if (this.fromBuying) {
      this.fields[0] = "from";
      await this.$store
        .dispatch("buyerPropertyMessages", this.property.id)
        .then(res => {
          this.isBusy = false;
          if (res.data.success) {
            this.messages = res.data.data;
            this.messagesPerUser = res.data.data;
          }
        });
    } else {
      await this.$store
        .dispatch("getPropertyMessages", this.property.id)
        .then(res => {
          this.isBusy = false;
          if (res[1].data.success) this.messages = res[1].data.data;
        });
    }

    this.isLastMessageFromSeller =
      _.get(this.messages[this.messages.length - 1],'userId', '') == this.property.sellerId;

    var items;

    if (this.fromBuying) {
      items = this.messages.filter(message => {
        return eval(
          `${message.userId} ${
            this.messages[this.messages.length - 1].userId ==
            this.authUser.buyer_seller_id
              ? ""
              : "!"
          }== ${this.authUser.buyer_seller_id}`
        );
      });
    } else {
      items = this.messages.filter(message => {
        return message.userId !== this.authUser.buyer_seller_id;
      });
    }
    items.sort((a, b) => {
      if (a.id < b.id) return 1;
      else if (a.id > b.id) return -1;
      return 0;
    });
    this.items = Array.from(new Set(items.map(item => item.userId)))
      .map(id => {
        return {
          id: items.find(item => item.userId == id).id,
          message: items.find(item => item.userId == id).message,
          propertyId: items.find(item => item.userId == id).propertyId,
          status: items.find(item => item.userId == id).status,
          subject: items.find(item => item.userId == id).subject,
          userId: items.find(item => item.userId == id).userId,
          userType: items.find(item => item.userId == id).userType,
          user: items.find(item => item.userId == id).user,
          isViewing: items.find(item => item.userId == id).isViewing,
          createdAt: items.find(item => item.userId == id).createdAt
        };
      })
      .filter(message => message.user);
      if(!this.offerTypes.length)
         this.$store
      .dispatch("getConfigurationsByKey", "offerType").then(res => {
        if (res.data.success) {
          this.offerTypes = Object.keys(res.data.data.val);
        }
      })
    },
    recipientPicture(profilePic){
      try {
        let pic = JSON.parse(profilePic);
        return _.defaultTo(_.get(pic,'[0]', false),`../assets/images/default-profile.webp`);
      } catch (err) {
        if(typeof profilePic == 'object' && profilePic instanceof Array){
          return _.defaultTo(_.get(profilePic,'[0]', false), require(`../assets/images/default-profile.webp`));
        }
        else
          return _.defaultTo(profilePic, require(`../assets/images/default-profile.webp`));
      }
    },
    addCommas(event) {
      this.offerAmount = this.formatter(
        event.target.value.toString().replace(/,/g, "")
      );
    },
    unBlockUser() {
      let foundId = _.get(
        this.blockedUsers.find(
          user => user.toUser.id == this.property.sellerId
        ),
        "id",
        ""
      );
      this.$store.dispatch("unBlockUser", foundId).then(res => {
        if (res == true) {
          this.$store.commit("setMessage", "User Unblocked");
          this.$store.dispatch("blockedUsers");
          this.$store.dispatch("blockedBy");
        } else
          this.$store.commit("setMessage", "User Not Unblocked");
      });
    },
    reportUser() {
      this.$store
        .dispatch("blockUser", {
          userId: this.property.sellerId,
          reason: "test",
          closure: "",
          type: 0
        })
        .then(res => {
          if (res == true)
            this.$store.commit("setMessage", "User Reported");
          else
            this.$store.commit("setMessage", "User Not Reported");
        });
    },
    blockUser() {
      this.$store
        .dispatch("blockUser", {
          userId: this.property.sellerId,
          reason: "test",
          closure: "",
          type: 1
        })
        .then(res => {
          if (res == true) {
            this.$store.commit("setMessage", "User Blocked");
            this.$store.dispatch("blockedUsers");
            this.$store.dispatch("blockedBy");
          } else
            this.$store.commit("setMessage", "User Not Blocked");
        });
    },
    makeOffer() {
      if (this.isUserBlocked || this.amIBlocked) return true;
      this.$store.commit("setShowLoader", true);
      const data = {
        propertyId: this.property.id,
        offerType: this.offerType,
        offerAmount: parseInt(this.offerAmount.replace(/,/g, "")),
        offerId: null
      };
      this.$store.dispatch("createOffer", data).then(res => {
        this.$store.commit("setShowLoader", false);
        if (res == true) {
          this.$refs[`model-1`].hide();
          this.offerAmount = "";
          this.$store.commit(
            "setMessage",
            "Offer made successfully!"
          );
        } else {
          alert("offer was not made");
        }
      });
    },
    showModal() {
      this.selectedMessageItem = { propertyId: this.property.id };
      this.enquireType = "viewing";
      this.$refs["model-1"].show();
    },
    addSlashes(event) {
      this.date = event.target.value
        .toString()
        .replace(/^(\d\d)(\d)$/g, "$1/$2")
        .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
        .replace(/[^\d\/]/g, "");
    },
    changeView(event) {
      this.enquireType = event.target.value;
    },
    checkIfUserHasViewing() {
      if (this.property.viewing.length) {
        let found = this.property.viewing.filter(
          viewing => viewing.buyerId == this.authUser.buyer_seller_id
        );
        return found ? found[0] : false;
      } else return false;
    },
    limitCharacters(message) {
      return message.length > 40 ? `${message.substr(0, 40)}...` : message;
    },
    updateViewing(message) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.isUserBlocked || this.amIBlocked) return true;
        this.$store.commit("setShowLoader", true);
        const data = {
          propertyId: message.propertyId,
          slotDate: (() => {
            if (
              new RegExp(
                /(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/]\d{4}/
              ).test(this.date)
            ) {
              return moment(
                `${this.date.split("/")[1]}/${this.date.split("/")[0]}/${
                  this.date.split("/")[2]
                }`
              ).format("YYYY-MM-DD");
            } else if (
              new RegExp(
                /(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-]\d{4}/
              ).test(this.date)
            ) {
              return moment(
                `${this.date.split("-")[1]}/${this.date.split("-")[0]}/${
                  this.date.split("-")[2]
                }`
              ).format("YYYY-MM-DD");
            } else return moment(this.date).format("YYYY-MM-DD");
          })(),
          slotTime: moment(this.time, "h:mm A").format("h:mm A"),
          slotEndTime: moment(this.time, "h:mm A")
            .add(this.duration, "minutes")
            .format("h:mm A"),
          buyerId: message.viewingObject.buyerId
        };
        this.$store
          .dispatch("updateViewing", {
            data: data,
            messageId: message.id,
            viewingId: message.viewingObject.id
          })
          .then(res => {
            this.$store.commit("setShowLoader", false);
            if (res == true) {
              this.shouldSuggest[message.id] = this.$set(
                this.shouldSuggest,
                message.id,
                !this.shouldSuggest[message.id]
              );
              if (this.fromBuying) {
                this.$store
                  .dispatch("buyerPropertyMessages", this.property.id)
                  .then(res => {
                    this.isBusy = false;
                    if (res.data.success) {
                      this.messages = res.data.data;
                      this.messagesPerUser = res.data.data;
                    }
                  });
              } else {
                this.$store
                  .dispatch("buyerSellerChat", {
                    propertyId: this.selectedMessageItem.propertyId,
                    buyerId: this.selectedMessageItem.userId
                  })
                  .then(res => {
                    if (res.data.success) {
                      this.messagesPerUser = res.data.data;
                    }
                  });
              }
              this.$store.commit(
                "setMessage",
                "Viewing request sent to buyer"
              );
            } else {
              alert("error");
            }
          });
      }
    },
    suggestBooking(message) {
      this.shouldSuggest[message.id] = this.$set(
        this.shouldSuggest,
        message.id,
        !this.shouldSuggest[message.id]
      );
      if (this.shouldSuggest[message.id])
        this.$nextTick(() => {
          document
            .querySelector(`.message-id-${message.id}`)
            .scrollIntoView({ behavior: "smooth" });
        });
    },
    rejectBooking(message) {
      if (this.isUserBlocked || this.amIBlocked) return true;
      this.$store
        .dispatch("rejectBooking", {
          timeSlotId: message.viewingObject.id,
          messageId: message.id
        })
        .then(res => {
          if (res == true) {
            this.$store.dispatch(
              "getBuyerSeller",
              this.authUser.buyer_seller_id
            );
            this.$store.commit("setMessage", "Viewing Rejected");
            if (this.fromBuying) {
              this.$store
                .dispatch("buyerPropertyMessages", this.property.id)
                .then(res => {
                  this.isBusy = false;
                  if (res.data.success) {
                    this.messages = res.data.data;
                    this.messagesPerUser = res.data.data;
                  }
                });
            } else {
              this.$store
                .dispatch("buyerSellerChat", {
                  propertyId: this.selectedMessageItem.propertyId,
                  buyerId: this.selectedMessageItem.userId
                })
                .then(res => {
                  if (res.data.success) {
                    this.messagesPerUser = res.data.data;
                  }
                });
            }
          } else alert("error in message status updation");
        });
    },
    bookTimeSlot() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.commit("setShowLoader", true);
        const data = {
          propertyId: this.property.id,
          slotDate: (() => {
            if (
              new RegExp(
                /(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/]\d{4}/
              ).test(this.date)
            ) {
              return moment(
                `${this.date.split("/")[1]}/${this.date.split("/")[0]}/${
                  this.date.split("/")[2]
                }`
              ).format("YYYY-MM-DD");
            } else if (
              new RegExp(
                /(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-]\d{4}/
              ).test(this.date)
            ) {
              return moment(
                `${this.date.split("-")[1]}/${this.date.split("-")[0]}/${
                  this.date.split("-")[2]
                }`
              ).format("YYYY-MM-DD");
            } else return moment(this.date).format("YYYY-MM-DD");
          })(),
          slotTime: moment(this.time, "h:mm A").format("h:mm A"),
          slotEndTime: moment(this.time, "h:mm A")
            .add(this.duration, "minutes")
            .format("h:mm A")
        };
        this.$store.dispatch("bookTimeSlot", data).then(res => {
          this.$store.commit("setShowLoader", false);
          if (res == true) {
            this.$refs[`model-1`].hide();
            this.$store.commit(
              "setMessage",
              "Viewing request sent to seller"
            );
          } else {
            alert("error");
          }
        });
      }
    },
    acceptBooking(message) {
      if (this.isUserBlocked || this.amIBlocked) return true;
      this.$store
        .dispatch("acceptBooking", {
          timeSlotId: message.viewingObject.id,
          messageId: message.id
        })
        .then(res => {
          if (res == true) {
            this.$store.dispatch(
              "getBuyerSeller",
              this.authUser.buyer_seller_id
            );
            this.$store.commit("setMessage", "Viewing Accepted");
            if (this.fromBuying) {
              this.$store
                .dispatch("buyerPropertyMessages", this.property.id)
                .then(res => {
                  this.isBusy = false;
                  if (res.data.success) {
                    this.messages = res.data.data;
                    this.messagesPerUser = res.data.data;
                  }
                });
            } else {
              this.$store
                .dispatch("buyerSellerChat", {
                  propertyId: this.selectedMessageItem.propertyId,
                  buyerId: this.selectedMessageItem.userId
                })
                .then(res => {
                  if (res.data.success) {
                    this.messagesPerUser = res.data.data;
                  }
                });
            }
          } else alert("error in message status updation");
        });
    },
    getDuration(start, end) {
      var now = moment(start, "h:mm A");
      var then = moment(end, "h:mm A");
      if (then.diff(now, "minutes") < 0)
        return then.diff(now, "minutes") == "-1380" ? "60" : "30";
      else return then.diff(now, "minutes");
    },
    convertDate(date) {
      return moment(date).isValid() ? moment(date).format("DD MMM YYYY") : "";
    },
    getName(id) {
      return this.participants.find(participant => participant.id == id).name;
    },
    changeFormat(date) {
      return moment(date).isValid() ? moment(date).format("DD-MM-YYYY") : "";
    },
    shown() {
      this.$nextTick(() => {
        document.querySelector(
          `#modal-1___BV_modal_header_`
        ).style.borderBottom = "0px";
        document.querySelector(`#modal-1___BV_modal_content_`).style.width =
          "600px";
        document
          .querySelector(`#modal-1___BV_modal_content_`)
          .style.setProperty("height", "calc(100vh - 9rem)");
      });
      let element = document.querySelector(".container-message-display");
      if (element)
        element.scrollTop = element.scrollHeight - element.clientHeight;
      $(".tabbed-content").each(function() {
        $(this).append('<ul class="content"></ul>');
      }),
        $(".tabs li").each(function() {
          var a = $(this),
            b = "";
          a.is(".tabs>li:first-child") && (b = ' class="active"');
          var c = a
            .find(".tab-content")
            .detach()
            .wrap("<li" + b + "></li>")
            .parent();
          a.closest(".tabbed-content")
            .find(".content")
            .append(c);
        }),
        $(".tabs li").click(function() {
          $(this)
            .closest(".tabs")
            .find("li")
            .removeClass("active"),
            $(this).addClass("active");
          var a = $(this).index() + 1;
          $(this)
            .closest(".tabbed-content")
            .find(".content>li")
            .removeClass("active"),
            $(this)
              .closest(".tabbed-content")
              .find(".content>li:nth-of-type(" + a + ")")
              .addClass("active");
        });
      $("#blocking").on("click", function() {
        // $('#dfone').show();
        $("#dfone").toggle();
      });
    },
    onType: function(event) {
      //here you can set any behavior
    },
    onMessageSubmit: function(message) {
      const data = {
        propertyId: this.selectedMessageItem.propertyId,
        message: message.content,
        messageId: this.fromBuying ? null : this.selectedMessageItem.id
      };
      if (this.isUserBlocked || this.amIBlocked) return true;
      this.$store.dispatch("createMessage", data).then(res => {
        if (res == true) {
          this.$store
            .dispatch("getPropertyMessages", this.property.id)
            .then(res => {
              if (res[1].data.success) {
                this.messages = res[1].data.data;
              }
            });
        } else {
          alert("message not sent");
        }
      });
    },
    onClose() {
      this.visible = false;
    },
    getUserName(id) {
      let keys = Object.keys(this.username);
      if (!keys.includes(id.toString())) {
        this.$store.dispatch("getBuyerSellerForBlog", id).then(res => {
          if (res) {
            this.$set(this.username, id, res.data.name);
          }
        });
      }
      return this.username[id];
    },
    async triggerModal(item) {
      this.enquireType = "question";
      this.selectedMessageItem = item;
      this.$refs["model-1"].show();
      if (!this.fromBuying) {
        this.$store
          .dispatch("buyerSellerChat", {
            propertyId: item.propertyId,
            buyerId: item.userId
          })
          .then(res => {
            if (res.data.success) {
              this.messagesPerUser = res.data.data;
            }
          });
      } else {
        this.$store
          .dispatch("buyerPropertyMessages", this.property.id)
          .then(res => {
            this.isBusy = false;
            if (res.data.success) {
              this.messages = res.data.data;
              this.messagesPerUser = res.data.data;
            }
          });
      }
      const messagesOfSelectedUser = _.defaultTo(this.messages, []).filter(message => message.userId == item.userId)
      console.log({messagesOfSelectedUser})
        for(let i = 0; i < messagesOfSelectedUser.length; i++){
          if(messagesOfSelectedUser[i].status == 'unread'){
          try{
          await this.$store
          .dispatch("updateMessageStatus", _.get(messagesOfSelectedUser[i], 'id', null))
          .then(res => {
            if (res == true) {
              this.refresh();
              this.$store
                .dispatch("getPropertyMessages", this.property.id)
                .then(res => {
                  if (res[1].data.success) {
                    console.log("emit")
                    this.$emit("componentMessages", res[1].data.data);
                  }
                });
              this.$store.dispatch(
                "getBuyerSeller",
                this.authUser.buyer_seller_id
              );
            } else alert("error in message status updation");
          });
          }
          catch(e){
            console.log({e})
          }
          
        }
      }
    }
  },
  created() {
    if (this.fromBuying) {
      this.fields[0] = "from";
    }
  },
  async mounted() {
    this.refresh();
  }
};
</script>
<style scoped>
@import url("../assets/css/fontawesome-all.css");
@import url("../assets/css/fontawesome-5-all.min.css");
@import url("../assets/css/font-awesome.min.css");
@import url("../assets/css/styles.css");

div >>> .container-send-message{
  padding: 0;
}

div >>> .material-design-icon__svg {
    width: 30px;
    height: 30px;
    fill: #548f4d;
    stroke: #548f4d;
    cursor: pointer;
    background-color: rgba(black,.2);
    border-radius: 50%;
    opacity: .9;
}


.ui-elements .button-tabs .tab-title {
  background: none;
}
.ui-elements .button-tabs .tabs {
  background: #b2c9ab !important;
}

.ui-elements .button-tabs .active .tab-title {
  background-color: #658c59;
}
.ui-elements .button-tabs .active .tab-title:hover {
  background: #658c59;
}

div >>> .message-text-box {
  padding: 5px !important;
  border-radius: 8px;
   border-top-right-radius:0px !important;
    border-top-left-radius: 0px !important;
  background-color: #f7f3f3;
  border: 1px solid rgb(221, 221, 221) !important;

}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgb(0 122 54 / 50%);
}
div >>> .container-message-manager {
  padding: 0px;
  padding-top: 6px;
}

div >>> .container-send-message {
  padding: 0px !important;
}

div >>> .quick-chat-container {
  height: calc(100vh - 19rem);
}
div >>> .modal-body {
  overflow: hidden;
}
div >>> .modal-header {
  padding-bottom: 0px;
}

div >>> .message-text-box {
  padding: 5px !important;
  border-radius: 8px;
  background-color: #f7f3f3;
  border: 1px solid rgb(221, 221, 221) !important;
}

div >>> .icon-send-message {
  padding-right: 0 !important;
  position: absolute;
  left: 89%;
  padding: 0px !important;
}


@media (min-width: 300px) and (max-width: 700px) {

 div >>> .modal-content{
    width:100% !important;
        height: auto !important;
  }

  .res-pl-0-pr-0{
    padding-left: 0px !important;
    padding-right:0px !important;
  }

  .for-display{
    display: none !important;
  }

  .res-full-width{
    width:100% !important;
  }

div >>> .active{
  margin-bottom: 0px!important;
}

div >>> .display-to-none{
  display: none;
}

  .res-bloc-popup{

        left: 54% !important;
    top: 3% !important;
    
   
width: 32% !important;

  }


  div >>> .icon-send-message{
    margin:0px !important;
     left: 85% !important;
  }

  div >>> .message-text{
    max-width:100% !important;
  }

}


@media (min-width: 800px) and (max-width: 30000px) {



.hide-for-big{
  display:none !important;
 
}
}

</style>
