<template>
  <div>
    <div ref="flipster" :class="['flipster', `flipsterUpdate${propertyId}`, 'd-flex', 'justify-content-center', 'overflow-hidden']">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import flipsterMixin from "../mixins/flipsterMixin";
export default {
  props: ["propertyId"],
  mixins: [flipsterMixin],
};
</script>

<style>
@import url("../assets/css/jquery.flipster.min.css");

.flipster--infinite-carousel .flipster__item--past-1 .flipster__item__content {
  transform: translateX(-50%) rotateY(45deg) scale(0.8);
  -ms-transform: translateX(-50%) rotateY(45deg) scale(0.8);
  -webkit-transform: translateX(-50%) rotateY(45deg) scale(0.8);
  -moz-transform: translateX(-50%) rotateY(45deg) scale(0.8);
  -o-transform: translateX(-50%) rotateY(45deg) scale(0.8);
}

.flipster--loop .flipster__item {
  position: absolute;
}
.flipster--loop .flipster__item--past-2 {
  transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
}
.flipster--loop .flipster__item--future-2 {
  transform: translateX(100%);
  -ms-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -o-transform: translateX(100%);
}
.flipster--loop .flipster__item--past-1 {
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.flipster--loop .flipster__item--future-1 {
  transform: translateX(50%);
  -ms-transform: translateX(50%);
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -o-transform: translateX(50%);
}
.flipster--infinite-carousel .flipster__container,
.flipster--infinite-carousel .flipster__item {
  transition: all 350ms ease-in-out;
  -ms-transition: all 350ms ease-in-out;
  -webkit-transition: all 350ms ease-in-out;
  -moz-transition: all 350ms ease-in-out;
  -o-transition: all 350ms ease-in-out;
  transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
  -ms-transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
  -webkit-transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
  -moz-transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
  -o-transition-timing-function: cubic-bezier(0.56, 0.12, 0.12, 0.98);
}
.flipster--infinite-carousel .flipster__item__content {
  transition: inherit;
  -ms-transition: inherit;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
}
.flipster--infinite-carousel .flipster__item {
  position: absolute;
  opacity: 0;
  perspective: 800px;
}
.flipster--infinite-carousel .flipster__item--past-2,
.flipster--infinite-carousel .flipster__item--future-2 {
  opacity: 0.6;
  transition-delay: 90ms;
  -ms-transition-delay: 90ms;
  -webkit-transition-delay: 90ms;
  -moz-transition-delay: 90ms;
  -o-transition-delay: 90ms;
}
.flipster--infinite-carousel .flipster__item--past-1,
.flipster--infinite-carousel .flipster__item--future-1 {
  opacity: 0.8;
  transition-delay: 60ms;
  -ms-transition-delay: 60ms;
  -webkit-transition-delay: 60ms;
  -moz-transition-delay: 60ms;
  -o-transition-delay: 60ms;
}
.flipster--infinite-carousel .flipster__item--current {
  opacity: 1;
  transition-delay: 0;
  -ms-transition-delay: 0;
  -webkit-transition-delay: 0;
  -moz-transition-delay: 0;
  -o-transition-delay: 0;
}
.flipster--infinite-carousel .flipster__item--past .flipster__item__content,
.flipster--infinite-carousel .flipster__item--future .flipster__item__content {
  transform: scale(0.4);
  -ms-transform: scale(0.4);
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -o-transform: scale(0.4);
}
.flipster--infinite-carousel .flipster__item--past-2 .flipster__item__content {
  transform: translateX(-75%) rotateY(45deg) scale(0.6);
  -ms-transform: translateX(-75%) rotateY(45deg) scale(0.6);
  -webkit-transform: translateX(-75%) rotateY(45deg) scale(0.6);
  -moz-transform: translateX(-75%) rotateY(45deg) scale(0.6);
  -o-transform: translateX(-75%) rotateY(45deg) scale(0.6);
}
.flipster--infinite-carousel
  .flipster__item--future-2
  .flipster__item__content {
  transform: translateX(75%) rotateY(-45deg) scale(0.6);
  -ms-transform: translateX(75%) rotateY(-45deg) scale(0.6);
  -webkit-transform: translateX(75%) rotateY(-45deg) scale(0.6);
  -moz-transform: translateX(75%) rotateY(-45deg) scale(0.6);
  -o-transform: translateX(75%) rotateY(-45deg) scale(0.6);
}
.flipster--infinite-carousel .flipster__item--past-1 .flipster__item__content {
  transform: translateX(-50%) rotateY(45deg) scale(0.8);
  -ms-transform: translateX(-50%) rotateY(45deg) scale(0.8);
  -webkit-transform: translateX(-50%) rotateY(45deg) scale(0.8);
  -moz-transform: translateX(-50%) rotateY(45deg) scale(0.8);
  -o-transform: translateX(-50%) rotateY(45deg) scale(0.8);
}
.flipster--infinite-carousel
  .flipster__item--future-1
  .flipster__item__content {
  transform: translateX(50%) rotateY(-45deg) scale(0.8);
  -ms-transform: translateX(50%) rotateY(-45deg) scale(0.8);
  -webkit-transform: translateX(50%) rotateY(-45deg) scale(0.8);
  -moz-transform: translateX(50%) rotateY(-45deg) scale(0.8);
  -o-transform: translateX(50%) rotateY(-45deg) scale(0.8);
}
.flipster--infinite-carousel .flipster__item--current .flipster__item__content {
  transform: translateX(0) rotateY(0deg) scale(1);
  -ms-transform: translateX(0) rotateY(0deg) scale(1);
  -webkit-transform: translateX(0) rotateY(0deg) scale(1);
  -moz-transform: translateX(0) rotateY(0deg) scale(1);
  -o-transform: translateX(0) rotateY(0deg) scale(1);
}
</style>