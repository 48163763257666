<template>
  <div class="container mb-3">
    <b-table :fields="fields" striped hover :items="viewings">
      <template #cell(date)="row">
        <div class="d-flex">
          <p class="tdSet">
            {{
              `${changeFormat(row.item.slotDate.slice(0, 10))} ${
                row.item.slotTime
              }`
            }}
          </p>
          <span class="ml-1">
            <i
              @click="editBooking(row)"
              class="fas fa-edit editIconSet ml-2"
              style="cursor: pointer"
            >
            </i>
            <button
              v-if="row.item.status == 'pending'"
              @click="acceptBooking(row)"
              class="listing-button-color btn btn-primary ml-2"
            >
              Accept
            </button>
          </span>
        </div>
      </template>
      <template #cell(status)="row">
        <p class="tdSet">
          {{
            capitalize(
              row.item.status == "accept" ? "accepted" : row.item.status
            )
          }}
        </p>
      </template>
      <template #cell(buyer)="row">
        <p class="tdSet">{{ getUserName(row.item.buyerId) }}</p>
      </template>
      <template #cell(previous_viewings)="row">
        <b-form-select
          v-if="showPreviousViewings(row.item.buyerId)"
          v-model="selected[row.item.buyerId]"
          style="background-color: rgb(234, 234, 234)"
          :options="getPreviousViewings(row.item.buyerId)"
        >
        </b-form-select>
        <p v-else>No Previous Viewings</p>
      </template>
    </b-table>
    <b-modal
      :ref="`modal-counter-${property.id}`"
      centered
      size="lg"
      hide-footer
      id="my-modal"
    >
      <div class="d-flex flex-column align-items-center">
        <p>Please enter your counter to Mrs Jones Offer of:</p>
        <strong
          ><p>£ {{ currentOffer.offerAmount }}</p></strong
        >
        <input
          v-model="counterOffer"
          type="text"
          placeholder="Enter counter amount"
        />
        <p class="mt-2">A message with your counter offer will be sent</p>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            size="sm"
            class="float-left listing-button-color-secondary btn btn-primary"
          >
            No
          </b-button>
          <b-button
            @click="sendCounterOffer"
            variant="primary"
            size="sm"
            class="float-right listing-button-color btn btn-primary"
          >
            Yes
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      @shown="setHeightReject(property.id)"
      :ref="`modal-reject-${property.id}`"
      centered
      title="Reject"
      id="my-modal1"
    >
      <div class="d-flex flex-column align-items-center">
        <p>Are you sure you wish to reject Mrs. Jones Offer of:</p>
        <strong
          ><p>£ {{ currentOffer.offerAmount }}</p></strong
        >
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            size="sm"
            class="float-left listing-button-color-secondary btn btn-primary"
          >
            No
          </b-button>
          <b-button
            @click="rejectOffer"
            variant="primary"
            size="sm"
            class="float-right listing-button-color btn btn-primary"
          >
            Yes
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
    @shown="setHeightAccept(property.id)"
      :ref="`modal-accept-${property.id}`"
      centered
      title="Accept"
      id="my-modal2"
    >
      <div class="d-flex flex-column align-items-center">
        <p>Are you sure you wish to accept Mrs. Jones Offer of:</p>
        <strong
          ><p>£ {{ currentOffer.offerAmount }}</p></strong
        >
        <p>
          Accepting this ofer will message all users with outstanding ofers to
          say you have accepted an ofer. Your listing will be marked as sold and
          removed from the For Sale Listings Page. Once accepting an ofer both
          you and the buyer will be requested to share solicitors details to
          proceed with the sale.
        </p>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            size="sm"
            class="float-left listing-button-color-secondary btn btn-primary"
          >
            No
          </b-button>
          <b-button
            @click="acceptOffer"
            variant="primary"
            size="sm"
            class="float-right listing-button-color btn btn-primary"
          >
            Yes
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      hide-footer
      centered
      ref="modal-4"
      size="lg"
      id="modal-4"
      title="Add Viewing"
    >
      <div class="row">
        <div class="col-md-4">
          <p style="line-height: 5px">Date</p>
          <span class="d-flex">
            <input
              style="overflow: auto; height: 31px"
              v-model="repeatFor"
              type="text"
              name=""
              id=""
            />
          </span>
        </div>
        <div class="col-md-4">
          <p style="line-height: 5px">Time</p>
          <span class="d-flex">
            <input
              style="overflow: auto; height: 31px"
              v-model="repeatFor"
              type="text"
              name=""
              id=""
            />
          </span>
        </div>
        <div class="col-md-4">
          <p style="line-height: 5px">Duration</p>
          <select style="padding: 4px" v-model="startTime" name="" id="">
            <option v-for="(time, index) in options" :key="index" :value="time.value">
              {{ time.tag }}
            </option>
          </select>
        </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { capitalize } from "../utils";
import { mapGetters } from "vuex";

export default {
  props: ["property"],
  mounted() {
    for (let z = 0; z < 2; z++) {
      for (let i = 1; i <= 12; i++) {
        for (let j = 0; j <= 55; j = j + 30) {
          this.options = [
            ...this.options,
            `${i}:${j.toString().length == 1 ? "0" : ""}${j} ${
              z == 0 ? "am" : "pm"
            }`,
          ];
        }
      }
    }
    let items = [...this.property.viewing];
    items.sort((a, b) => {
      if (a.id < b.id) return 1;
      else if (a.id > b.id) return -1;
      return 0;
    });
    this.viewings = Array.from(new Set(items.map((item) => item.buyerId))).map(
      (id) => {
        return {
          buyerId: items.find((item) => item.buyerId == id).buyerId,
          id: items.find((item) => item.buyerId == id).id,
          propertyId: items.find((item) => item.buyerId == id).propertyId,
          sellerTimeSlotId: items.find((item) => item.buyerId == id)
            .sellerTimeSlotId,
          slotDate: items.find((item) => item.buyerId == id).slotDate,
          slotTime: items.find((item) => item.buyerId == id).slotTime,
          slotEndTime: items.find((item) => item.buyerId == id).slotEndTime,
          status: items.find((item) => item.buyerId == id).status,
        };
      }
    );
    items.forEach((item) => {
      if (items.filter((v) => v.buyerId == item.buyerId).length > 1) {
        if (
          item.id !==
          this.viewings.find((view) => view.buyerId == item.buyerId).id
        ) {
          this.$set(this.selected, item.buyerId, item);
        }
      } else this.$set(this.selected, item.buyerId, null);
    });
  },
  data() {
    return {
      repeatFor: 1,
      startDate: "",
      startTime: "",
      endTime: "",
      events: [],
      selected: {},
      viewings: [],
      offers: {},
      selectedSlot: null,
      username: {},
      counterOffer: "",
      currentOffer: {},
      time: "",
      value: "",
      options: [],
      customCell: {
        backgroundColor: "red",
      },
      fields: ["buyer", "date", "previous_viewings", "status"],
    };
  },
   computed:{
        ...mapGetters({
            authUser: 'auth',
        })
    },
  methods: {
    capitalize: capitalize,
    setHeightReject(propertyId){
      document
        .querySelector(`#reject-modal-${propertyId}___BV_modal_content_`)
        .setAttribute(
          "style",
          "height : auto !important; display: block !important"
        );
      document
        .querySelector("#reject-modal-" + propertyId)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#reject-modal-${propertyId}___BV_modal_body_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#reject-modal-${propertyId}___BV_modal_backdrop_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#reject-modal-${propertyId}___BV_modal_outer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#reject-modal-${propertyId}___BV_modal_header_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#reject-modal-${propertyId}___BV_modal_footer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#reject-modal-${propertyId}___BV_modal_title_`)
        .setAttribute("style", "display: block !important");

    },
    setHeightAccept(propertyId){
      document
        .querySelector(`#accept-modal-${propertyId}___BV_modal_content_`)
        .setAttribute(
          "style",
          "height : auto !important; display: block !important"
        );
      document
        .querySelector("#accept-modal-" + propertyId)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#accept-modal-${propertyId}___BV_modal_body_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#accept-modal-${propertyId}___BV_modal_backdrop_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#accept-modal-${propertyId}___BV_modal_outer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#accept-modal-${propertyId}___BV_modal_header_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#accept-modal-${propertyId}___BV_modal_footer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#accept-modal-${propertyId}___BV_modal_title_`)
        .setAttribute("style", "display: block !important");
      
    },
    acceptBooking(id) {
      this.$store
        .dispatch("acceptBooking", id)
        .then((res) => {
          if (res == true) {
            this.$store.commit("setMessage", "Viewing Accepted");
            this.$store.dispatch("getUserProperties").then((res) => {
              if (res == true) console.log("success");
              else alert("Error in property fecthing");
            });
          } else alert("error in message status updation");
        });
    },
    customLabelDate(date) {
      return new Date(date).getDate();
    },
    eventDropped(event) {
      const data = {
        propertyId: JSON.parse(event.event.content).propertyId,
        sellerTimeSlotId: JSON.parse(event.event.content).sellerTimeSlotId,
        slotDate: moment(event.event.start).format("YYYY-MM-DD"),
        slotTime: moment(event.event.start).format("h:mm A"),
        slotEndTime: moment(event.event.end).format("h:mm A"),
        status: JSON.parse(event.event.content).status,
      };
      console.log({ event }, { data });
      this.$store
        .dispatch("updateViewing", {
          data: data,
          id: JSON.parse(event.event.content).id,
        })
        .then((res) => {
          if (res == true) this.$store.dispatch("getUserProperties");
        });
    },
    async createTimeSlot() {
      let timeStart = moment(this.startTime, "h:mm A").format("HH:mm:ss");
      let timeEnd = moment(this.endTime, "h:mm A").format("HH:mm:ss");
      for (let i = 0; i < this.repeatFor; i++) {
        const data = {
          propertyId: this.property.id,
          date: moment(this.startDate).add("days", i).format("YYYY-MM-DD"),
          timeStart: timeStart,
          timeEnd: timeEnd,
        };
        await this.$store.dispatch("createTimeSlot", data);
      }
      this.$store
        .dispatch("propertyTimeSlots", this.property.id)
        .then((res) => {
          if (res.success) {
            this.timeSlots = res.data;
          }
        });
      this.startTime = "";
      this.endTime = "";
      this.startDate = "";
      this.repeatFor = 1;
    },
    convertDate(date) {
      return moment(date).format("hh:mm:ss a");
    },
    getPreviousViewings(buyerId) {
      let data = this.property.viewing.filter((view) => {
        return (
          view.buyerId == buyerId &&
          (this.viewings.find((v) => v.id == view.id)
            ? this.viewings.find((v) => v.id == view.id).id != view.id
            : true)
        );
      });
      data = data.map((view) => {
        return {
          value: view,
          text: `${moment(view.slotDate).format("DD-MM-YYYY")} ${
            view.slotTime
          }`,
        };
      });
      return data;
    },
    showPreviousViewings(buyerId) {
      return (
        this.property.viewing.filter((view) => view.buyerId == buyerId).length >
        1
      );
    },
    changeFormat(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    acceptBooking(row) {
      console.log(row);
      this.$store
        .dispatch("acceptBooking", row.item.id)
        .then((res) => {
          if (res == true) {
            const data = {
              propertyId: row.item.propertyId,
              date: row.item.slotDate,
              timeStart: row.item.slotTime,
              timeEnd: row.item.slotEndTime,
              status: "accept",
            };
            this.$store.dispatch("updateTimeSlot", {
              data: data,
              id: row.item.sellerTimeSlotId,
            });
            this.$store.dispatch(
                "getBuyerSeller",
                this.authUser.buyer_seller_id
              );
            this.$store.commit("setMessage", "Viewing Accepted");
            this.$store.dispatch("getUserProperties").then((res) => {
              if (res == true) console.log("success");
              else alert("Error in property fecthing");
            });
          } else alert("error in message status updation");
        });
    },
    getOfferByUser(row) {
      let matchedOffers = this.property.offers.filter((offer) => {
        return row.item.buyerId == offer.userId;
      });
      console.log({ matchedOffers });
      if (matchedOffers[matchedOffers.length - 1]) {
        this.offers[row.item.buyerId] = matchedOffers[matchedOffers.length - 1];
        return `£ ${matchedOffers[matchedOffers.length - 1].offerAmount}`;
      } else {
        return "";
      }
    },
    editBooking(row) {
      console.log({ row });
      this.startTime = row.item.slotTime;
      this.$refs["modal-4"].show();
      this.value = row.item.slotDate;
    },
    getUserName(id) {
      let keys = Object.keys(this.username);
      if (!keys.includes(id.toString())) {
        this.$store.dispatch("getBuyerSellerForBlog", id).then((res) => {
          if (res) {
            this.$set(this.username, id, res.data.name);
          }
        });
      }
      return this.username[id];
    },
    acceptOffer() {
      this.$store
        .dispatch("acceptOffer", this.currentOffer.id)
        .then((res) => {
          if (res == true) {
            this.$store.dispatch(
                "getBuyerSeller",
                this.authUser.buyer_seller_id
              );
            this.$router.push(`/accepted-offer/${this.propertyId}/${this.currentOffer.userId}`).catch(() =>{});
          } else alert("error");
        });
    },
    rejectOffer() {
      this.$store
        .dispatch("rejectOffer", this.currentOffer.id)
        .then((res) => {
          if (res == true) {
            this.$store.dispatch(
                "Auth/getBuyerSeller",
                this.authUser.buyer_seller_id
              );
            this.$refs[`modal-reject-${this.property.id}`].hide();
            this.$store.commit("setMessage", "Offer rejected!");
          } else alert("error");
        });
    },
    selectOffer(row) {
      this.currentOffer = this.offers[row.item.buyerId];
    },
    sendCounterOffer() {
      const data = {
        propertyId: this.currentOffer.propertyId,
        offerId: this.currentOffer.id,
        offerAmount: this.counterOffer,
        offerType: this.currentOffer.offerType,
      };
      this.$store.dispatch("createOffer", data).then((res) => {
        if (res == true) {
          this.$store.dispatch(
                "getBuyerSeller",
                this.authUser.buyer_seller_id
              );
          this.$refs[`modal-counter-${this.property.id}`].hide();
          this.$store.commit("setMessage", "Counter offer sent!");
        } else alert("counter offer not sent");
      });
    },
  },
};
</script>

<style scoped>
@import url("../assets/css/styles.css");
@import url("../assets/css/fontawesome-all.css");
@import url("../assets/css/fontawesome-5-all.min.css");
@import url("../assets/css/font-awesome.min.css");

label {
  display: unset !important;
  width: unset !important;
  padding: 0px !important;
}

div >>> .marked-cell {
  background-color: yellow;
}

.tdSet {
  padding-top: 5px;
  padding-bottom: 0px;
}
.editIconSet {
  padding-top: 9px;
  padding-bottom: 0px;
}
select:focus {
  border-color: #548f4d;
}
</style>