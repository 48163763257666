<template>
  <div class="row justify-content-md-center">
    <div class="col-md-4">
      <form @submit.prevent="submit" autocomplete="off">
        <h4 class="mb-4">Your Details</h4>
        <div class="form-group">
          <input
            v-model="name"
            style="height: 50px"
            class="form-control w-100"
            placeholder="Your Full Name"
            type="text"
          />
        </div>
        <div class="form-group">
          <input
            v-model="solicitorName"
            style="height: 50px"
            class="form-control w-100"
            placeholder="Solicitor Name"
            type="text"
          />
        </div>
        <div class="form-group">
          <input
            v-model="solicitorEmail"
            style="height: 50px"
            class="form-control w-100"
            placeholder="Solicitor Email"
            type="email"
          />
        </div>
        <div class="form-group">
          <input
            @keydown="onlyNumberInput($event)"
            v-model="solicitorPhone"
            style="height: 50px"
            class="form-control w-100"
            placeholder="Solicitor Number"
            type="text"
          />
        </div>
        <div class="form-group">
          <input
            v-model="solicitorAddress"
            style="height: 50px"
            class="form-control w-100"
            placeholder="Solicitor Address"
            type="text"
          />
        </div>
        <button
          data-v-172e701b=""
          class="btn mb-5 btn-yellow bset res-button-how res-dd-none for-display"
          style="color: white"
        >
          Update
        </button>
      </form>
    </div>
    <div class="col-md-4">
      <form @submit.prevent="" autocomplete="off">
        <h4 class="mb-4">{{ fromBuying ? "Seller" : "Buyer" }}'s Details</h4>
        <div class="form-group">
          <input
            style="height: 50px"
            class="form-control w-100"
            placeholder="Your Full Name"
            :value="
              _.get(
                fromBuying ? buyerSolicitor : sellerSolicitor,
                'userName',
                ''
              )
            "
            type="text"
            disabled
          />
        </div>
        <div class="form-group">
          <input
            style="height: 50px"
            class="form-control w-100"
            placeholder="Solicitor Name"
            :value="
              _.get(
                fromBuying ? buyerSolicitor : sellerSolicitor,
                'solicitorName',
                ''
              )
            "
            type="text"
            disabled
          />
        </div>
        <div class="form-group">
          <input
            style="height: 50px"
            class="form-control w-100"
            placeholder="Solicitor Email"
            :value="
              _.get(
                fromBuying ? buyerSolicitor : sellerSolicitor,
                'solicitorEmail',
                ''
              )
            "
            type="email"
            disabled
          />
        </div>
        <div class="form-group">
          <input
            style="height: 50px"
            class="form-control w-100"
            placeholder="Solicitor Number"
            :value="
              _.get(
                fromBuying ? buyerSolicitor : sellerSolicitor,
                'solicitorPhone',
                ''
              )
            "
            type="text"
            disabled
          />
        </div>
        <div class="form-group">
          <input
            style="height: 50px"
            class="form-control w-100"
            placeholder="Solicitor Address"
            :value="
              _.get(
                fromBuying ? buyerSolicitor : sellerSolicitor,
                'solicitorAddress',
                ''
              )
            "
            type="text"
            disabled
          />
        </div>
        <button
          data-v-172e701b=""
          class="btn mb-5 btn-yellow bset full-width res-button-how res-dd-none hide-for-big"
          style="color: white"
        >
          Update
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { onlyNumberInput } from "../utils";

export default {
  data() {
    return {
      buyerSolicitor: null,
      sellerSolicitor: null,
      name: "",
      solicitorName: "",
      solicitorEmail: "",
      solicitorPhone: "",
      solicitorAddress: "",
    };
  },
  validations: {
    name: {
      required,
    },
    solicitorName: {
      required,
    },
    solicitorEmail: {
      required,
    },
    solicitorPhone: {
      required,
    },
    solicitorAddress: {
      required,
    },
  },
  watch: {
    property() {
      this.buyerSolicitor = _.defaultTo(
        this.property.solicitors.find(
          (solicitor) => solicitor.userType == "seller"
        ),
        null
      );
    },
    buyerSeller() {
      this.name = this.buyerSeller.name;
    },
  },
  computed: {
    _() {
      return _;
    },
    ...mapGetters({
      buyerSeller: "getBuyerSeller",
    }),
  },
  methods: {
    onlyNumberInput: onlyNumberInput,
    findFirstAcceptedOffer(){
      const offers = _.get(this.property, 'offers', [])
      let acceptedOffer = offers.find(offer => offer.status == 'accept')
      return _.get(_.defaultTo(acceptedOffer, false), 'userId', '')
    },
    submit() {
      if (
        this.property.solicitors.find(
          (solicitor) => solicitor.userType == "buyer"
        ) &&
        this.fromBuying
      ) {
        const data = {
          solicitorName: this.solicitorName,
          solicitorEmail: this.solicitorEmail,
          solicitorPhone: this.solicitorPhone,
          solicitorAddress: this.solicitorAddress,
          toUserId: this.fromBuying
            ? this.property.sellerId
            : this.buyerSeller.id,
        };
        this.$store.dispatch('solicitorUpdate', {
          data: data,
          id: _.get(this.property.solicitors.find(solicitor => solicitor.userType == 'buyer'), 'id', null)
        })
        .then(res => {
          if(res == true)
            this.$store.commit('setMessage', "Solicitor information has been updated")
          else
            this.$store.commit('setMessage', "Solicitor information has not been updated")
        })
      } else if (
        this.property.solicitors.find(
          (solicitor) => solicitor.userType == "seller"
        ) &&
        !this.fromBuying
      ) {
        const data = {
          solicitorName: this.solicitorName,
          solicitorEmail: this.solicitorEmail,
          solicitorPhone: this.solicitorPhone,
          solicitorAddress: this.solicitorAddress,
          toUserId: this.fromBuying
            ? this.property.sellerId
            : this.findFirstAcceptedOffer(),
        };
        this.$store.dispatch('solicitorUpdate', {
          data: data,
          id: _.get(this.property.solicitors.find(solicitor => solicitor.userType == 'seller'), 'id', null)
        })
        .then(res => {
          if(res == true)
            this.$store.commit('setMessage', "Solicitor information has been updated")
          else
            this.$store.commit('setMessage', "Solicitor information has not been updated")
        })
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const data = {
            propertyId: this.property.id,
            userName: this.name,
            solicitorName: this.solicitorName,
            solicitorEmail: this.solicitorEmail,
            solicitorPhone: this.solicitorPhone,
            solicitorAddress: this.solicitorAddress,
            toUserId: this.fromBuying
              ? this.property.sellerId
              : this.findFirstAcceptedOffer(),
          };
          this.$store.dispatch("solicitorAdd", data).then((res) => {
            if (res == true) {
              this.$store.commit("setMessage", "An Email has been sent!");
              this.$router.push("/").catch(() => {});
            } else
              this.$store.commit(
                "setMessage",
                "Solicitor details were not added"
              );
          });
        }
      }
    },
  },
  props: ["property", "fromBuying"],
  mounted() {
    this.name = this.buyerSeller.name;
    this.buyerSolicitor = _.defaultTo(
      this.property.solicitors.find(
        (solicitor) => solicitor.userType == "seller"
      ),
      null
    );
    this.sellerSolicitor = _.defaultTo(
      this.property.solicitors.find(
        (solicitor) => solicitor.userType == "buyer"
      ),
      null
    );
    this.solicitorName = this.fromBuying
      ? _.get(this.sellerSolicitor, 'solicitorName','')
      : _.get(this.buyerSolicitor, 'solicitorName','')
    this.solicitorEmail = this.fromBuying
      ? _.get(this.sellerSolicitor, 'solicitorEmail','')
      : _.get(this.buyerSolicitor, 'solicitorEmail','');
    this.solicitorPhone = this.fromBuying
      ? _.get(this.sellerSolicitor, 'solicitorPhone','')
      : _.get(this.buyerSolicitor, 'solicitorPhone','');
    this.solicitorAddress = this.fromBuying
      ? _.get(this.sellerSolicitor, 'solicitorAddress','')
      : _.get(this.buyerSolicitor, 'solicitorAddress','');
  },
};
</script>


<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>
.w-100{
  width: 100% !important;;
}

@media (min-width: 300px) and (max-width: 700px) {
  .for-display{
    display: none !important;
  }
  .full-width{
width: 100% !important;;
  }
}

@media (min-width: 800px) and (max-width: 30000px) {



.hide-for-big{
  display:none !important;
 
}
}
</style>