<template>
  <div :data-property-id="`${property.id}`" :ref="`propertyNo${property.id}`">
    <div style="margin-right:-25px;" class="row featured portfolio-items mb-4 res-simple-list-property">
      <div
        class="
          position-static
          item
          col-lg-5 col-md-12 col-xs-12
          landscapes
          sale
          pr-0
          pb-0
        "
      >
        <div
          style="
           
            box-shadow: 0 0 10px 1px rgb(71 85 95 / 8%);
            height: 310px;
            background-color: white;
          "
          class="project-single mb-0 bb-0 inner-pages portfolio res-remove-border-left res-t-center"
        >
          <div class="homes max" style="max-width: 500px; max-height: 311px;">
            <!-- homes img -->
            <div style="position: absolute;
                top: 90%;
                left: 91%;">
            <i
              v-show="property.images.length"
              @click="enlargeFlipster"
              style="
                z-index: 999;
                
                font-size: 20px;
                color: #272b32;
              "
              class="fa fa-search magnifer hover"
            ></i>
            </div>
            <div style="  position: absolute;
                top: 92%;
                
                left: 47%;
                z-index: 99">
            <p
              style="
                z-index: 999;
               
                font-size: 18px;
                color: #272b32;
              "
            >
              {{ currentIndex + 1 }}/{{ property.images.length }}
            </p>
            </div>
            <NewFlipster
              v-if="property.images"
              :key="property.id"
              :propertyId="property.id"
              class="mt-1"
            >
              <ul
                :class="[
                  'flip-items',
                  property.images.length == 1
                    ? 'd-flex justify-content-center'
                    : '',
                ]"
              >
                <li
                  :id="index"
                  v-for="(image, index) in property.images"
                  :key="index"
                  class="d-flex justify-content-center align-items-center"
                  style="height: 300px; width: 319px; background-color: #f7f7f7"
                >
                  <img
                    v-if="!isVideo(image)"
                    style="
                      object-fit: cover;
                      width: max-content;
                      cursor: pointer;
                    "
                    @click="enlargeFlipster"
                    :src="`${image}`"
                  />
                  <video v-else width="320" height="240" controls>
                    <source
                      :src="`${image}`"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </li>
              </ul>
            </NewFlipster>
          </div>
        </div>
      </div>
      <!-- homes content -->
      <div style="padding: 0px !important;   height:310px; res-remove-border-left ;   box-shadow: 0 0 10px 1px rgb(71 85 95 / 8%);"
        class="
          col-md-7
          res-radious
          height0
          res-height0
          length-set-description
          homes-content
          pb-0
          mb-44
        "
      >
        <slot name="main" :show="show">
          <div :class="_.get(property.propertyPackage, `[${_.defaultTo(_.get(property.propertyPackage, 'length', null), 1)-1}].isPremiumListing`, false) ? 'bg-green res-h10 res-bg-greenborder' : 'res-h10'" style="height:100px;">
          <div class="inner-right p25 d-block w-100">
            <div class="left-title" style="float: left">
              <div class="dollar">
                <router-link style="text-decoration: none;" :to="`/property-details/${property.id}`">
                <h3 style="font-weight: bold;" :class="['h5','res-font',_.get(property.propertyPackage, `[${_.defaultTo(_.get(property.propertyPackage, 'length', null), 1)-1}].isPremiumListing`, false) ? 'c-white' : '']">£{{ formatter(property.price) }}</h3>
                </router-link>
                <router-link class="for-display" style="color:#666 !important; font-weight: bold; " :to="`/property-details/${property.id}`">
                  <slot name="title" style="color:#666 !important; font-weight: bold; ">
                    <span
                    :class="_.get(property.propertyPackage, `[${_.defaultTo(_.get(property.propertyPackage, 'length', null), 1)-1}].isPremiumListing`, false) ? 'c-white' : ''"
                      ><i :class="['fas', 'res-w30' , 'fa-map-marker-alt',_.get(property.propertyPackage, `[${_.defaultTo(_.get(property.propertyPackage, 'length', null), 1)-1}].isPremiumListing`, false) ? 'c-white' : '']"></i>
                      {{
                        `${property.city}, ${property.postCode.split(" ")[0].substr(0,4)}${
                          property.county ? `, ${property.county}` : ""
                        }`
                      }}</span
                    >
                  </slot>
                </router-link>
              </div>
            </div>

            <div :class="['right-title' ,fromAddProperty ? 'res-float-add' : 'res-float-add' ,'text-right']" style="float: right">
              <div>
              <slot name="removeFromBuying">
              </slot>
              <slot name="actions">
                <img 
                    @click="$refs[`share-modal${property.id}`].show()"
                    :class="['w-30', 'margin-r-5', 'res-w30', 'share-green-fill',_.get(property.propertyPackage, `[${_.defaultTo(_.get(property.propertyPackage, 'length', null), 1)-1}].isPremiumListing`, false) ? 'share-white-filled' : '']"
                   
                    :src="
                      require(`../assets/images/icons/share-icon-${_.get(property.propertyPackage, `[${_.defaultTo(_.get(property.propertyPackage, 'length', null), 1)-1}].isPremiumListing`, false) ? 'white' : 'green'}.webp`)
                    "
                  />
                  <img
                  v-if="!checkIfFavourite()"
                    @click="addToFavourites"
            :class="['w-30', 'res-w30', 'heart-green-fill','hover',_.get(property.propertyPackage, `[${_.defaultTo(_.get(property.propertyPackage, 'length', null), 1)-1}].isPremiumListing`, false) ? 'heart-white-filled' : '']"
                    
                    
                    :src="
                     require(`../assets/images/icons/heart-${_.get(property.propertyPackage, `[${_.defaultTo(_.get(property.propertyPackage, 'length', null), 1)-1}].isPremiumListing`, false) ? 'white' : 'green'}.webp`)
                    "
                  />
                  <img
                  v-if="checkIfFavourite()"
                    @click="addToFavourites"
                    class="w-30 res-w30 chat-delete hover"
                    
                    :src="
                     require(`../assets/images/icons/heart-${_.get(property.propertyPackage, `[${_.defaultTo(_.get(property.propertyPackage, 'length', null), 1)-1}].isPremiumListing`, false) ? 'white-filled' : 'green-filled'}.webp`)
                    "
                  />
              </slot>
              </div>
              <slot name="createdAt">
              <p v-if="!_.get(property.propertyPackage, `[${_.defaultTo(_.get(property.propertyPackage, 'length', null), 1)-1}].isPremiumListing`, false)" class="res-p-list ml-5 res-hide"><i class="fas fa-calendar"></i> {{ capitalizeDate(timeFromNow()) }}</p>
              <p  v-else style="font-weight: bold;" class="res-p-list ml-5 res-hide c-white text-uppercase">Premium Listing</p>
              </slot>
            </div>
            <div class="for-mobile">
              <router-link class="for-mobile" style="color:#666 !important; font-weight: bold; " :to="`/property-details/${property.id}`">
                  <slot name="title" style="color:#666 !important; font-weight: bold; ">
                    <span
                    :class="_.get(property.propertyPackage, `[${_.defaultTo(_.get(property.propertyPackage, 'length', null), 1)-1}].isPremiumListing`, false) ? 'c-white' : ''"
                      ><i :class="['fas', 'res-w30' , 'fa-map-marker-alt',_.get(property.propertyPackage, `[${_.defaultTo(_.get(property.propertyPackage, 'length', null), 1)-1}].isPremiumListing`, false) ? 'c-white' : '']"></i>
                      {{
                        `${property.city}, ${property.postCode.split(" ")[0].substr(0,4)}${
                          property.county ? `, ${property.county}` : ""
                        }`
                      }}</span
                    >
                  </slot>
                </router-link></div>
          </div>
          </div>

         
<hr class="res-mr-85 hide-for-big" style="width: 100%;/* color: black; */border-top: 1px solid rgba(0,0,0,.1);margin-top:0px!important;margin-left: 0px;top: 1px;margin-bottom: 0px;padding-top: 0px;/* height: 31px; */margin-top: 4px;">
          <p class="res-h177 plr25"
            style="
              text-align: justify;
              width: 100%;
              height:103px;
              display: block;
              margin-top: 8px;
            "
          >
            <slot name="descriptionTitle" :property="property"> </slot>
          </p>

          <hr  class="res-mr-35" style="width: 100%" />

          <div class="lower-design plr25">
            <div
              class="inner-lower-design res-mobile-change lowerbed-icons"
              
            >
              <div class="bed res-margin-20">
                <span class="res-listing-m-0" style="  margin-right: 10px;  font-size: 20px;
    font-weight: 500;">{{ property.noOfBedrooms }}</span>
                <img style="
                    color: rgb(107, 107, 107);
                    font-size: 25px;
                    color: #618c57;
                    height: 24px;
                   width: 24px;
                    margin-top: -7px;" 
                    :src="require('../assets/images/icons/Bed-Icon.webp')" />
              </div>

              <div class="bath res-margin-20">
                <span class="res-listing-m-0" style=" margin-right: 10px;   font-size: 20px;
    font-weight: 500;">{{
                  property.noOfBathrooms
                }}</span>
                <img style="
                    color: rgb(107, 107, 107);
                    font-size: 25px;
                    color: #618c57;
                    height: 24px;
                    width: 24px;
                    margin-top: -7px;" 
                    :src="require('../assets/images/icons/Bathroom-Icon.webp')" />
              </div>

              <div class="list ">
                <span class="res-listing-m-0" style=" margin-right: 10px;   font-size: 20px;font-weight: 500;">{{
                  property.epcCertificate
                    ? property.epcCertificate.epcRating
                    : ""
                }}</span>
                 <img style="
                    color: rgb(107, 107, 107);
                    font-size: 25px;
                    color: #618c57;
                    height: 24px;
                    width: 24px;
                    margin-top: -7px;" 
                    :src="require('../assets/images/icons/EPC-Icon.webp')" />
              </div>
              <slot v-if="screenWidth >= 760" name="buttons">
                <slot name="fullListing">
              <button class="res-button-how for-display"
                @click="$router.push(`/property-details/${property.id}`)"
                style="
                  width: 25%;
                  padding: 10px 0px;
                  border: none;
                  background-color: #618c57;
                  color: #fff;
                  border-radius: 5px;
               
                  font-size: 15px;
                "
              >
                View
              </button>
                </slot>
              <button class="res-button-how for-display"
                @click="openModal"
                style="
                  width: 25%;
                  padding: 10px 0px;
                  border: none;
                  background-color: #618c57;
                  color: #fff;
                  border-radius: 5px;
                  font-size: 15px;
                "
              >
                Enquire
              </button>
              </slot>
            </div>
            <slot v-if="screenWidth <= 760" name="buttonsAlt" >
            <div class="here"> 
              <slot name="fullListingAlt">
                <button class="res-button-how hide-for-big"
                @click="$router.push(`/property-details/${property.id}`)"
                style="
                  width: 100%;
                  margin-top: 10px;
                  margin-bottom: 5px;
                  padding: 10px 0px;
                  border: none;
                  background-color: #618c57;
                  color: #fff;
                  border-radius: 5px;
               
                  font-size: 15px;
                "
              >
                View
              </button>
              </slot>
               <button :class="['res-button-how','hide-for-big', enquireMargin ? 'mb-3' : '']"
                @click="openModal"
                style="
                  width: 100%;
                  margin-top: 5px;
                  margin-bottom: 10px;
                  padding: 10px 0px;
                  border: none;
                  background-color: #618c57;
                  color: #fff;
                  border-radius: 5px;
                  font-size: 15px;
                "
              >
                Enquire
              </button>
              </div>
            </slot>
          </div>
        </slot>
      </div>
    </div>
    <div class="enquirediv">
    <b-modal
      @shown="shown()"
      hide-footer
      size="lg"
      centered
      scrollable
      :ref="`modal-enquire${property.id}`"
      :id="`modal-enquire${property.id}`"
      title="Enquire"
    >
      <!-- <p>I would like to...</p> -->

      <div class="inner-pages ui-elements hd-white">
        <div class="tabbed-content button-tabs">
          <ul class="tabs">
            <li :class="enquireType == 'question' ? 'active' : ''">
              <div @click="enquireType == 'question'" class="tab-title">
                <span>Ask a question</span>
              </div>
            </li>
            <li :class="enquireType == 'viewing' ? 'active' : ''">
              <div @click="enquireType == 'viewing'" class="tab-title">
                <span>Propose a Viewing</span>
              </div>
            </li>
            <li :class="enquireType == 'offer' ? 'active' : ''">
              <div @click="enquireType == 'offer'" class="tab-title">
                <span>Make an Offer</span>
              </div>
            </li>
            <span class="for-display">
             <div id="blocking"  style="padding: 13px;
             cursor: pointer;
    display: inline-block;
    text-align: center;
    border-radius: 9px;
    width: auto;
    color: white;">
                <i class="fa fa-ellipsis-v mr-1 "></i>
              
              </div>
               
              
              </span>
          </ul>
           <ul class="res-bloc-popup" style="cursor: pointer; display:none; text-align: center;
    width: 23%;
    border: 1px solid #658C59;
    border-radius: 8px;
    line-height: 32px;
   
    position: absolute;
    background: white;
    left: 65%;
    top: 6%;" id="dfone">
                  <li v-if="!amIBlocked" @click="isUserBlocked ? unBlockUser() : blockUser()" style=" border-bottom: 1px solid #658C59;">
                    {{ isUserBlocked ? 'Unb' : 'B' }}lock User
                    </li>
                    <li v-else style=" border-bottom: 1px solid #658C59;">
                      You are blocked
                    </li>
                    <li @click="reportUser">
                    Report
                    </li>
                  </ul>
          <ul class="content">
            <li :class="enquireType == 'question' ? 'active' : ''">
              <div class="tab-content">
                <div class="mt-3">
                  <Chat
          ref="chat"
          :participants="participants"
          :myself="myself"
          :messages="allMessages"
          :chat-title="chatTitle"
          :placeholder="placeholder"
          :colors="colors"
          :send-images="false"
          :border-style="borderStyle"
          :hide-close-button="hideCloseButton"
          :close-button-icon-size="closeButtonIconSize"
          :submit-icon-size="submitIconSize"
          :async-mode="asyncMode"
          :scroll-bottom="scrollBottom"
          :display-header="false"
          :profile-picture-config="profilePictureConfig"
          :timestamp-config="timestampConfig"
          @onMessageSubmit="onMessageSubmit"
          @onType="onType"
          @onClose="onClose"
        >
          <template v-slot:header>
            <div>
              <p
                v-for="(participant, index) in participants"
                :key="index"
                class="custom-title"
              >
                {{ participant.name }}
              </p>
            </div>
          </template>
          <template #content-participant="{ message }">
            <div v-if="message.additionalInfo.isViewing">
              <div
                v-if="message.additionalInfo.viewingObject.status == 'accept'"
              >
                <p>The viewing</p>
                <p style="line-height: 1px">On {{convertDate(message.additionalInfo.viewingObject.slotDate)}}</p>
                <p style="line-height: 1px">At {{ message.additionalInfo.viewingObject.slotTime }}</p>
                <p>Has been accepted.</p>
              </div>
              <div
                v-else-if="
                  message.additionalInfo.viewingObject.status == 'reject'
                "
              >
                <p>The viewing</p>
                <p style="line-height: 1px">On {{convertDate(message.additionalInfo.viewingObject.slotDate)}}</p>
                <p style="line-height: 1px">At {{ message.additionalInfo.viewingObject.slotTime }}</p>
                <p>Has been rejected, you can propose another time.</p>
              </div>
              <div
                v-else-if="
                  message.additionalInfo.viewingObject.status == 'suggest'
                "
              >
                <p>Has suggested a viewing</p>
                <p style="line-height: 1px">On {{convertDate(message.additionalInfo.viewingObject.slotDate)}}</p>
                <p style="line-height: 1px">At {{ message.additionalInfo.viewingObject.slotTime }}</p>
                <div>
                  <button
                    @click="acceptBooking(message.additionalInfo)"
                    class="btn btn-primary mr-2"
                    style="background: #3e4452; border-color: #3e4452"
                  >Accept</button>
                  <button
                    @click="rejectBooking(message.additionalInfo)"
                    class="btn btn-primary mr-2"
                    style="background: #3e4452; border-color: #3e4452"
                  >Reject</button>
                  <button
                    @click="suggestBooking(message.additionalInfo)"
                    class="btn btn-primary"
                    style="background: #3e4452; border-color: #3e4452"
                  >Suggest</button>
                </div>
              </div>
              <div
                :class="`message-id-${message.additionalInfo.id}`"
                v-if="shouldSuggest[message.additionalInfo.id]"
              >
                <p class="mb-0">Date (e.g. 20/07/2021)</p>
              
                  <input
                    @input="addSlashes($event)"
                    style="overflow: auto; "
                    v-model="date"
                    type="text"
                    name=""
                    id=""
                  />
              
                <p
                  style="margin-top: -13px"
                  class="error mb-0"
                  v-if="$v.date.$dirty && !$v.date.isDateValid"
                >
                  Date is invalid!
                </p>
                <p class="res-mt-12 " style="line-height: 5px">Time (e.g. 12 pm)</p>
                <span style="margin-top: -14px" class="d-flex">
                  <input
                    style="overflow: auto;"
                    v-model="time"
                    type="text"
                    name=""
                    id=""
                  />
                </span>
                <p
                  style="margin-top: -11px; margin-bottom: 0px"
                  class="error"
                  v-if="$v.time.$dirty && !$v.time.isTimeValid"
                >
                  Time is invalid!
                </p>
                <br />
                <button
                  @click="updateViewing(message.additionalInfo)"
                  class="btn btn-primary mt-2 res-full-width"
                  style="
                    background: #3e4452;
                    border-color: #3e4452;
                    padding-left: 25px;
                    padding-right: 25px;
                    margin-left: -5px;
                  "
                >Send</button>
              </div>
            </div>
            <p style="line-height: 15px" v-else>{{ message.content }}</p>
          </template>
          <template #content-myself="{ message }">
            <div
              v-if="
                message.additionalInfo
                  ? message.additionalInfo.isViewing
                  : false
              "
            >
              <div
                v-if="message.additionalInfo.viewingObject.status == 'accept'"
              >
                <p>The viewing</p>
                <p style="line-height: 1px">On {{convertDate(message.additionalInfo.viewingObject.slotDate)}}</p>
                <p style="line-height: 1px">At {{ message.additionalInfo.viewingObject.slotTime }}</p>
                <p>Has been accepted</p>
              </div>
              <div
                v-else-if="
                  message.additionalInfo.viewingObject.status == 'pending'
                "
              >
                <p>I would like to view this property</p>
                <p style="line-height: 1px">On {{convertDate(message.additionalInfo.viewingObject.slotDate)}}</p>
                <p style="line-height: 1px">At {{ message.additionalInfo.viewingObject.slotTime }}</p>
              </div>
              <div
                v-else-if="
                  message.additionalInfo.viewingObject.status == 'reject'
                "
              >
                <p>The viewing</p>
                <p style="line-height: 1px">On {{convertDate(message.additionalInfo.viewingObject.slotDate)}}</p>
                <p style="line-height: 1px">At {{ message.additionalInfo.viewingObject.slotTime }}</p>
                <p>Has been rejected, you can propose another time.</p>
              </div>
              <div
                v-else-if="
                  message.additionalInfo.viewingObject.status == 'suggest'
                "
              >
                <p>I would like the viewing</p>
                <p style="line-height: 1px">On {{convertDate(message.additionalInfo.viewingObject.slotDate)}}</p>
                <p style="line-height: 1px">At {{ message.additionalInfo.viewingObject.slotTime }}</p>
              </div>
            </div>
            <p style="line-height: 15px" v-else>{{ message.content }}</p>
          </template>
        </Chat>

                </div>
              </div>
            </li>
            <li :class="enquireType == 'viewing' ? 'active' : ''">
              <div class="tab-content">
                <div class="row">
                   <div class='col-md-12'>
                            <p style="margin-bottom: 30px;">
                              Like this property? Why not take a look at it.
                              <br>
                              <br>
                              You can propose a date and time for viewing. This
                              will send a message to your seller informing them
                              that you are interested.
                            </p>
                          </div>
                 
                   <div class="col-md-6">
                    <p style="line-height: 5px">Date (e.g. 20/07/2021)</p>
                 
                      <input
                        @input="addSlashes($event)"
                        style="overflow: auto;"
                        v-model="date"
                        type="text"
                        name=""
                        id=""
                      />
                   
                    <p style="    position: absolute;
    top: 70px;"
                      class="error"
                      v-if="$v.date.$dirty && !$v.date.isDateValid"
                    >
                      Date is invalid!
                    </p>
                      <button  style="margin-top:4px;    width: 268px; background-color: #595959;
    color: white;" class="btn btn-yellow res-bb res-full-width for-display"> Cancel </button>
                  </div>
                  <div class="col-md-6 res-mt-1rem">
                    <p style="line-height: 5px">Time (e.g. 12 pm)</p>
                  
                      <input
                        style="overflow: auto;"
                        v-model="time"
                        type="text"
                        name=""
                        id=""
                      />
                    
                    <p style="    position: absolute;
    top: 70px;"
                      class="error"
                      v-if="$v.time.$dirty && !$v.time.isTimeValid"
                    >
                      Time is invalid!
                    </p>
                     <button  style="margin-top:4px;    width: 268px; background-color: #595959;
    color: white;" class="btn btn-yellow res-bb res-full-width hide-for-big "> Cancel </button>
                    <button @click="bookTimeSlot" style="margin-top:4px;     width: 268px;color: white;
    background-color: #658C59" class="btn btn-yellow res-bb res-full-width"> Send </button>
                  </div>
                  
                </div>
              </div>
            </li>
            <li :class="enquireType == 'offer' ? 'active' : ''">
              <div class="tab-content">
                <div class="mt-3 resss-margin-top-0">
                  <div class='col-md-12 res-pl-0-pr-0'>
                            <p class="res-mb-13" style="margin-bottom: 30px;">
                              Like this property? Why not make an offer.
                              <br>
                              <br>
                              You can send an offer to the seller including the amount you would like to offer and the type e.g. Mortgage or Cash.
                              <br>
                               Asking Price: <strong style="color:#658C59"> £ {{ formatter(property.price) }} </strong>
                            </p>
                          </div>
                 <div style="margin:auto;" class="row">
                    <div class="col-md-6 res-pl-0-pr-0">
                    <p class="m-0">Value </p>
                     <input style="margin-top: 5px;"
                     @input="addCommas($event)"
                     :value="offerAmount"
                      type="text"
                      placeholder="Enter your offer"
                    />
                    <i data-v-1081ae79="" class="priceicon">£</i>
                     <button style="    width: 255px; background-color: #595959;
    color: white;" class="btn btn-yellow res-bb res-full-width for-display"> Cancel </button>
                    </div>
                      <div class="col-md-6 res-pl-0-pr-0">
                    <p  class="m-0 res-mt-12 ">Type</p>
                     <select style="    margin-bottom: 24px;margin-top: 5px;" v-model="offerType"  name="" id="">
                      <option
                        v-for="(item, index) in offerTypes"
                        :key="index"
                        :value="item.toLowerCase()"
                      >
                        {{ item }}
                      </option>
                    </select>
                     <button style="    width: 255px; background-color: #595959;
    color: white;" class="btn btn-yellow res-bb res-full-width mb-1 hide-for-big"> Cancel </button>
                     <button @click="makeOffer" style="    width: 257px; background-color: #658c59;
    color: white;" class="btn btn-yellow res-bb res-full-width"> Send </button>
                    </div>
                   </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <template #modal-footer>
        <div>
          <button
            @click="$refs[`modal-enquire${property.id}`].hide()"
            class="btn btn-primary listing-button-color-secondary"
          >
            Cancel
          </button>
          <button
            @click="
              enquireType == 'question'
                ? sendMessage()
                : enquireType == 'offer'
                ? makeOffer()
                : bookTimeSlot()
            "
            class="btn btn-primary listing-button-color"
          >
            Send
          </button>
        </div>
      </template>
    </b-modal>
    </div>
    <b-modal
      @shown="modalOpened(property)"
      centered
      :hide-header="true"
      :hide-footer="true"
      size="xl"
      :ref="`modal${property.id}`"
      id="modal-1"
      title="BootstrapVue"
    >
      <p
        style="
          z-index: 999;
          position: sticky;
          position: -webkit-sticky;
          top: 94%;
          text-align-last: center;
         text-align: -webkit-center;
          font-size: 20px;
          color: #272b32;
        "
      >
        {{ currentIndex + 1 }}/{{ property.images.length }}
      </p>
      <NewFlipster :propertyId="property.id">
        <i @click="$refs[`modal${property.id}`].hide()"  class="fa fa-times hover fatimes" aria-hidden="true"></i>
        <ul style="    transform: translateX(-354.625px) !important;"
          :class="[
            'flip-items',
            property.images.length == 1 ? 'd-flex justify-content-center' : '',
          ]"
        >
          <li
            :id="index"
            v-for="(image, index) in property.images"
            :key="index"
            class="d-flex justify-content-center align-items-center"
            :style="`height: ${screenHeight / (screenHeight >= 500 ? 1.2 : 1)}px; width: ${
              screenWidth / (screenWidth >= 500 ? 2.4 : 1)
            }px; background-color: ${screenWidth >= 500 ? 'grey' : 'grey'}`"
          >
            <img v-if="!isVideo(image)" :src="`${image}`" />
            <video
              v-else
              :width="`${screenWidth / (screenWidth >= 500 ? 2.4 : 1)}`"
              :height="`${screenHeight / (screenHeight >= 500 ? 1.4 : 1)}`"
              controls
            >
              <source
                :src="`${image}`"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </li>
        </ul>
      </NewFlipster>
    </b-modal>
    <b-modal
      @shown="setWidth"
      hide-footer
      centered
      scrollable
      :ref="`share-modal${property.id}`"
      :id="`share-modal${property.id}`"
      title="Share"
    >
    <ul style="list-style: none; padding: 0px;">
      <li class="pb-2 pt-2 d-flex justify-content-center" style="border-bottom : 1px solid grey;">
      <ShareNetwork
                    network="facebook"
                    :url="`https://vencasa.netlify.app/property-details/${this.property.id}`"
                    title="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    description="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    quote="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    hashtags="vencasa"
                  >
                    <i style="color: #548f4d" class="fab fah fa-lg fa-facebook"></i> Facebook
                  </ShareNetwork>
      </li>
      <li class="pb-2 pt-2 d-flex justify-content-center" style="border-bottom : 1px solid grey;">
                  <ShareNetwork
                    network="twitter"
                    :url="`https://vencasa.netlify.app/property-details/${this.property.id}`"
                    title="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    description="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    quote="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    hashtags="vencasa"
                  >
                    <i style="color: #548f4d" class="fab fah fa-lg fa-twitter"></i> Twitter
                  </ShareNetwork>
      </li>
      <li class="pb-2 pt-2 d-flex justify-content-center" style="border-bottom : 1px solid grey;">
                  <ShareNetwork
                    network="linkedin"
                    :url="`https://vencasa.netlify.app/property-details/${this.property.id}`"
                    title="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    description="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    quote="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    hashtags="vencasa"
                  >
                    <i style="color: #548f4d" class="fab fah fa-lg fa-linkedin-in"></i> LinkedIn
                  </ShareNetwork>
      </li>
      <li class="pb-2 pt-2 d-flex justify-content-center" style="border-bottom : 1px solid grey;">
                  <ShareNetwork
                    network="email"
                    :url="`https://vencasa.netlify.app/property-details/${this.property.id}`"
                    title="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    description="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    quote="Hello from Vencasa, A brand new, extremely convenient way to buy and sell properties. "
                    hashtags="vencasa"
                  >
                    <i style="color: #548f4d" class="fas fa-lg fa-envelope"></i> Email
                  </ShareNetwork>
      </li>
    </ul>
    </b-modal>
    <div v-if="show">
      <component
        @componentMessages="messageStatusChanged($event)"
        @componentOffers="offerStatusChanged($event)"
        :property="property"
        :offers="fromBuying ? sellerOffers() : propertyOffers"
        :messages="property.messages"
        :is="currentComponent"
        :fromBuying="fromBuying"
      ></component>
    </div>
  </div>
</template>

<script>
import { formatter } from "../utils";
import _ from "lodash";
import NewFlipster from "./new-flipster.vue";
import moment from "moment";
import { mapGetters } from "vuex";
import ListingTabsOffers from "./listing-tabs-offers";
import ListingTabsViewings from "./listing-tabs-viewings";
import ListingTabsMessages from "./listing-tabs-messages";
import ListingTabsSolicitor from "./listing-tabs-solicitor.vue";
import { Chat } from "vue-quick-chat-slotted";
import "vue-quick-chat-slotted/dist/vue-quick-chat.css";
import $ from "jquery";

export default {
  data() {
    return {
      
      propertyOffers: [],
      shouldSuggest: {},
      date: "",
      time: "",
      duration: "",
      screenHeight: window.innerHeight,
      screenWidth: window.innerWidth,
      images: [],
      currentIndex: 0,
      messages: [],
      events: [],
      shouldShow: false,
      triggerModal: false,
      options: [
        { tag: `30 minutes`, value: 30 },
        { tag: `1 Hour`, value: 60 },
      ],
      selectedSlot: "",
      enquireType: "question",
      subject: "",
      user: {},
      value: "",
      time: "",
      messageBody: "",
      offerType: "cash",
      offerAmount: "",
      timeSlots: [],
      chatTitle: "My chat title",
      placeholder: "Type a message",
      colors: {
        header: {
          bg: "#d30303",
          text: "#fff",
        },
        message: {
          myself: {
            bg: "#548f4d",
            text: "white",
          },
          others: {
            bg: "#fff",
            text: "black",
          },
          messagesDisplay: {
            bg: "#f7f3f3",
          },
        },
        submitIcon: "#548f4d",
        submitImageIcon: "#548f4d",
      },
      borderStyle: {
        topLeft: "10px",
        topRight: "10px",
        bottomLeft: "10px",
        bottomRight: "10px",
      },
      hideCloseButton: false,
      submitIconSize: 25,
      closeButtonIconSize: "20px",
      asyncMode: false,
      scrollBottom: {
        messageSent: true,
        messageReceived: true,
      },
      displayHeader: true,
      profilePictureConfig: {
        others: true,
        myself: true,
        styles: {
          width: "30px",
          height: "30px",
          borderRadius: "50%",
        },
      },
      timestampConfig: {
        format: "HH:mm",
        relative: false,
      },
    };
  },
  validations: {
    date: {
      isDateValid: (value) => {
        if (
          new RegExp(/(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/]\d{4}/).test(
            value
          )
        ) {
          return (
            moment(
              `${value.split("/")[1]}/${value.split("/")[0]}/${
                value.split("/")[2]
              }`
            ).isValid() &&
            (moment
              .duration(
                moment(
                  `${value.split("/")[1]}/${value.split("/")[0]}/${
                    value.split("/")[2]
                  }`
                ).diff(moment())
              )
              .asHours()
              .toFixed(0) >= 0 ||
              moment
                .duration(
                  moment(
                    `${value.split("/")[1]}/${value.split("/")[0]}/${
                      value.split("/")[2]
                    }`
                  ).diff(moment())
                )
                .asHours()
                .toFixed(0) >= -24)
          );
        } else if (
          new RegExp(/(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-]\d{4}/).test(
            value
          )
        ) {
          return (
            moment(
              `${value.split("-")[1]}/${value.split("-")[0]}/${
                value.split("-")[2]
              }`
            ).isValid() &&
            (moment
              .duration(
                moment(
                  `${value.split("/")[1]}/${value.split("/")[0]}/${
                    value.split("/")[2]
                  }`
                ).diff(moment())
              )
              .asHours()
              .toFixed(0) >= 0 ||
              moment
                .duration(
                  moment(
                    `${value.split("/")[1]}/${value.split("/")[0]}/${
                      value.split("/")[2]
                    }`
                  ).diff(moment())
                )
                .asHours()
                .toFixed(0) >= -24)
          );
        } else
          return (
            moment(value).isValid() &&
            (moment
              .duration(moment(value).diff(moment()))
              .asHours()
              .toFixed(0) >= 0 ||
              moment
                .duration(moment(value).diff(moment()))
                .asHours()
                .toFixed(0) >= -24)
          );
      },
    },
    time: {
      isTimeValid: (value) => {
        return (
          moment(value, "h:mm A", true).isValid() ||
          moment(value, "h:mmA", true).isValid() ||
          moment(value, "h A", true).isValid() ||
          moment(value, "hA", true).isValid()
        );
      },
    },
  },
  components: {
    NewFlipster,
    ListingTabsOffers,
    ListingTabsViewings,
    ListingTabsMessages,
    ListingTabsSolicitor,
    Chat,
  },
  computed: {
    profilePic() {
      try {
        let pic = JSON.parse(this.buyerSeller.profilePic);
        return _.defaultTo(_.get(pic,'[0]', false),`../assets/images/default-profile.webp`);
      } catch (err) {
        if(typeof this.buyerSeller.profilePic == 'object' && this.buyerSeller.profilePic instanceof Array){
          return _.defaultTo(_.get(this.buyerSeller.profilePic,'[0]', false), require(`../assets/images/default-profile.webp`));
        }
        else
          return _.get(this.buyerSeller, 'profilePic', require(`../assets/images/default-profile.webp`));
      }
    },
    _(){
      return _;
    },
    isUserBlocked(){
      return this.blockedUsers.find(user => user.toUser.id == this.property.sellerId)
    },
    amIBlocked(){
      return this.blockedByUsers.find(user => user.fromUser.id == this.property.sellerId)
    },
    ...mapGetters({
      buyerSeller: "getBuyerSeller",
      blockedByUsers: "getBlockedByUsers",
      blockedUsers: "getBlockedUsers",
      properties: "getFavourites",
      check: "check",
      authUser: "auth",
    }),
    allMessages() {
      return this.messages.map((message) => {
        var date = new Date(message.createdAt);
        return {
          content: message.message,
          additionalInfo: message,
          myself: message.userId == this.authUser.buyer_seller_id,
          participantId: message.userId,
          timestamp: {
            year: date.getFullYear(),
            month: date.getMonth(),
            day: date.getDate(),
            hour: date.getHours(),
            minute: date.getMinutes(),
            second: date.getSeconds(),
            millisecond: date.getMilliseconds(),
          },
          type: "text",
        };
      });
    },
    participants() {
      return this.messages.map((message) => {
        return {
          name: message.user.user_name,
          id: message.userId,
          profilePicture: message.user.profilePic
            ? this.recipientPicture(message.user.profilePic)
            : require(`../assets/images/default-profile.webp`)
        };
      });
    },
    myself() {
      if (Object.keys(this.authUser).length) {
        return {
          name: "Me",
          id: this.authUser.buyer_seller_id,
          profilePicture: this.profilePic,
        };
      } else return {};
    },
  },
  props: [
    "enquireMargin",
    "property",
    "show",
    "currentComponent",
    "observer",
    "fromBuying",
    "offerTypes",
    "openCreateBox",
    "fromAddProperty",
  ],
  watch: {
    'openCreateBox.toggle'(){
      if(this.openCreateBox)
      this.$refs[`modal-enquire${this.property.id}`].show()
    },
    enquireType(val) {
      if (val == "question") {
        setTimeout(() => {
          // document.querySelector(".quick-chat-container").style.height =
          //   "400px";
          document.querySelector(".container-message-display").scrollTop =
            document.querySelector(".container-message-display").scrollHeight;
        }, 100);
      }
    },
  },
  methods: {
    recipientPicture(profilePic){
      try {
        let pic = JSON.parse(profilePic);
        return _.defaultTo(_.get(pic,'[0]', false),`../assets/images/default-profile.webp`);
      } catch (err) {
        if(typeof profilePic == 'object' && profilePic instanceof Array){
          return _.defaultTo(_.get(profilePic,'[0]', false), require(`../assets/images/default-profile.webp`));
        }
        else
          return _.defaultTo(profilePic, require(`../assets/images/default-profile.webp`));
      }
    },
    setWidth(){
      document.querySelector(`#share-modal${this.property.id}___BV_modal_content_`).style.width = '300px'
    },
    capitalizeDate(str){
      let tokens = str.split(' ')
      return `${tokens[0]} ${tokens[1][0].toUpperCase()+tokens[1].slice(1,tokens[1].length)} ${tokens[2]}`
    },
    unBlockUser(){
      let foundId = _.get(this.blockedUsers.find(user => user.toUser.id == this.property.sellerId), 'id', '')
      this.$store.dispatch('unBlockUser', foundId)
      .then(res => {
        if(res == true){
          this.$store.commit("setMessage", "User Unblocked");
          this.$store.dispatch('blockedUsers')
          this.$store.dispatch('blockedBy')
        }
        else
          this.$store.commit("setMessage", "User Not Unblocked");
      })
    },
    reportUser(){
      this.$store.dispatch('blockUser',
        {
          userId: this.property.sellerId,
          reason: 'test',
          closure: '',
          type: 0
        }
      )
      .then(res => {
        if(res == true)
          this.$store.commit("setMessage", "User Reported");
        else
          this.$store.commit("setMessage", "User Not Reported");
      })
    },
    blockUser(){
      this.$store.dispatch('blockUser',
        {
          userId: this.property.sellerId,
          reason: 'test',
          closure: '',
          type: 1
        }
      )
      .then(res => {
        if(res == true){
          this.$store.commit("setMessage", "User Blocked");
          this.$store.dispatch('blockedUsers')
          this.$store.dispatch('blockedBy')
        }
        else
          this.$store.commit("setMessage", "User Not Blocked");
      })
    },
    messageStatusChanged(messages) {
      this.$emit("componentMessages", {
        messages: messages,
        property: this.property,
      });
    },
    offerStatusChanged(offers){
      this.$emit('componentOffers', { offers: offers, property: this.property })
    },
    sellerOffers(){
      return this.propertyOffers
        .filter((offer) => offer.propertyId == this.property.id)
        .filter((offer) => {
          return offer.offerGroupId == `${this.property.id},${this.property.sellerId}-${this.authUser.buyer_seller_id}`
        })
    },
    addSlashes(event){
       this.date = event.target.value.toString().replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')
    },
    formatter: formatter,
    addCommas(event) {
      this.offerAmount = this.formatter(
        event.target.value.toString().replace(/,/g, "")
      );
    },
    suggestBooking(message) {
      this.shouldSuggest[message.id] = this.$set(
        this.shouldSuggest,
        message.id,
        !this.shouldSuggest[message.id]
      );
      if (this.shouldSuggest[message.id])
        this.$nextTick(() => {
          document
            .querySelector(`.message-id-${message.id}`)
            .scrollIntoView({ behavior: "smooth" });
        });
    },
    rejectBooking(message) {
      this.$store
        .dispatch("rejectBooking", {
          timeSlotId: message.viewingObject.id,
          messageId: message.id,
        })
        .then((res) => {
          if (res == true) {
             if(this.isUserBlocked || this.amIBlocked)
          return true
            this.$store.dispatch(
              "Auth/getBuyerSeller",
              this.authUser.buyer_seller_id
            );
            this.$store.commit("setMessage", "Viewing Rejected");
            this.$store
              .dispatch("buyerPropertyMessages", this.property.id)
              .then((res) => {
                this.isBusy = false;
                if (res.data.success) {
                  this.messages = res.data.data;
                }
              });
          } else alert("error in message status updation");
        });
    },
    updateViewing(message) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.commit("setShowLoader", true);
        const data = {
          propertyId: message.propertyId,
          slotDate: (() => {
            if (
              new RegExp(
                /(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/]\d{4}/
              ).test(this.date)
            ) {
              return moment(
                `${this.date.split("/")[1]}/${this.date.split("/")[0]}/${
                  this.date.split("/")[2]
                }`
              ).format("YYYY-MM-DD");
            } else if (
              new RegExp(
                /(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-]\d{4}/
              ).test(this.date)
            ) {
              return moment(
                `${this.date.split("-")[1]}/${this.date.split("-")[0]}/${
                  this.date.split("-")[2]
                }`
              ).format("YYYY-MM-DD");
            } else return moment(this.date).format("YYYY-MM-DD");
          })(),
          slotTime: moment(this.time, "h:mm A").format("h:mm A"),
          slotEndTime: moment(this.time, "h:mm A")
            .add(this.duration, "minutes")
            .format("h:mm A"),
          buyerId: message.viewingObject.buyerId,
        };
        this.$store
          .dispatch("updateViewing", {
            data: data,
            messageId: message.id,
            viewingId: message.viewingObject.id,
          })
          .then((res) => {
            this.$store.commit("setShowLoader", false);
            if (res == true) {
              this.shouldSuggest[message.id] = this.$set(
                this.shouldSuggest,
                message.id,
                !this.shouldSuggest[message.id]
              );
              this.$store.commit(
                "setMessage",
                "Viewing request sent to Seller"
              );
              this.$store
                .dispatch("buyerPropertyMessages", this.property.id)
                .then((res) => {
                  this.isBusy = false;
                  if (res.data.success) {
                    this.messages = res.data.data;
                  }
                });
            } else {
              alert("error");
            }
          });
      }
    },
    acceptBooking(message) {
      this.$store
        .dispatch("acceptBooking", {
          timeSlotId: message.viewingObject.id,
          messageId: message.id,
        })
        .then((res) => {
          if (res == true) {
             if(this.isUserBlocked || this.amIBlocked)
          return true
            this.$store.dispatch(
              "Auth/getBuyerSeller",
              this.authUser.buyer_seller_id
            );
            this.$store.commit("setMessage", "Viewing Accepted");
            this.$store
              .dispatch("buyerPropertyMessages", this.property.id)
              .then((res) => {
                this.isBusy = false;
                if (res.data.success) {
                  this.messages = res.data.data;
                }
              });
          } else alert("error in message status updation");
        });
    },
    isVideo(path) {
      if(path)
      return (
        path.split(".")[3] == "mp4" ||
        path.split(".")[3] == "mkv" ||
        path.split(".")[3] == "avi"
      );
      else
        return ''
    },
    getDuration(start, end) {
      var now = moment(start, "h:mm A");
      var then = moment(end, "h:mm A");
      if (then.diff(now, "minutes") < 0)
        return then.diff(now, "minutes") == "-1380" ? "60" : "30";
      else return then.diff(now, "minutes");
    },
    convertDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
    modalOpened(property) {
      this.$nextTick(() => {
        document
          .querySelector(".modal-xl")
          .addEventListener("click", (event) => {
            if (event.target.classList.contains("flipster"))
              this.$refs[`modal${property.id}`].hide();
          });
        setTimeout(() => {
          $(`.flipsterUpdate${property.id}`)[1]?.focus()?.trigger({
            type: "keypress",
            which: 13,
          });
        }, 100);
      });
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
      this.counterInit(1);
    },
    async shown() {
      this.$nextTick(() => {
        document.querySelector(`#modal-enquire${this.property.id}___BV_modal_header_`).style.borderBottom = '0px'
        document.querySelector(`#modal-enquire${this.property.id}___BV_modal_content_`).style.width = '600px'
        document.querySelector(`#modal-enquire${this.property.id}___BV_modal_content_`).style.setProperty('height', 'calc(100vh - 1rem)', 'important')
        $(".tabbed-content").each(function () {
          $(this).append('<ul class="content"></ul>');
        }),
          $(".tabs li").each(function () {
            var a = $(this),
              b = "";
            a.is(".tabs>li:first-child") && (b = ' class="active"');
            var c = a
              .find(".tab-content")
              .detach()
              .wrap("<li" + b + "></li>")
              .parent();
            a.closest(".tabbed-content").find(".content").append(c);
          }),
          $(".tabs li").click(function () {
            $(this).closest(".tabs").find("li").removeClass("active"),
              $(this).addClass("active");
            var a = $(this).index() + 1;
            $(this)
              .closest(".tabbed-content")
              .find(".content>li")
              .removeClass("active"),
              $(this)
                .closest(".tabbed-content")
                .find(".content>li:nth-of-type(" + a + ")")
                .addClass("active");
          });
          $('#blocking').on('click', function(){
            $('#dfone').toggle();
          })
      });
      this.openCreateBox
        ? (this.enquireType = this.openCreateBox.type)
        : (this.enquireType = "question");
      await this.$store
        .dispatch("buyerPropertyMessages", this.property.id)
        .then((res) => {
          this.isBusy = false;
          if (res.data.success) {
            this.messages = res.data.data;
          }
        });
    },
    checkIfFavourite() {
      return this.properties.some((property) => {
        return property.id == this.property.id;
      });
    },
    enlargeFlipster() {
      this.$refs[`modal${this.property.id}`].show();
    },
    addToFavourites() {
      if (!this.check) {
        this.$store.commit("setLoginRequired", true);
        return;
      }
      if(this.isUserBlocked || this.amIBlocked)
          return true
      if (this.checkIfFavourite()) {
        this.$store
          .dispatch("deleteFavourite", this.property.id)
          .then((res) => {
            if (res == true) {
              this.$store.commit(
                "setMessage",
                "Removed from Favourites"
              );
              this.$store.dispatch("getFavourites");
            } else alert("error");
          });
      } else {
        const data = {
          propertyId: this.property.id,
        };
         if(this.isUserBlocked || this.amIBlocked)
          return true
        this.$store.dispatch("createFavourite", data).then((res) => {
          if (res == true) {
            this.$store.commit(
              "setMessage",
              "Added To Favourites"
            );
            this.$store.dispatch("getFavourites");
          } else alert("error");
        });
      }
    },
    bookTimeSlot() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.commit("setShowLoader", true);
        const data = {
          propertyId: this.property.id,
          slotDate: (() => {
            if (
              new RegExp(
                /(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/]\d{4}/
              ).test(this.date)
            ) {
              return moment(
                `${this.date.split("/")[1]}/${this.date.split("/")[0]}/${
                  this.date.split("/")[2]
                }`
              ).format("YYYY-MM-DD");
            } else if (
              new RegExp(
                /(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-]\d{4}/
              ).test(this.date)
            ) {
              return moment(
                `${this.date.split("-")[1]}/${this.date.split("-")[0]}/${
                  this.date.split("-")[2]
                }`
              ).format("YYYY-MM-DD");
            } else return moment(this.date).format("YYYY-MM-DD");
          })(),
          slotTime: moment(this.time, "h:mm A").format("h:mm A"),
          slotEndTime: moment(this.time, "h:mm A")
            .add(this.duration, "minutes")
            .format("h:mm A"),
        };
        if(this.isUserBlocked || this.amIBlocked)
          return true
        this.$store.dispatch("bookTimeSlot", data).then((res) => {
          this.$store.commit("setShowLoader", false);
          if (res == true) {
            this.$refs[`modal-enquire${this.property.id}`].hide();
            this.$store.commit(
              "Miscellaneous/setMessage",
              "Viewing request sent to seller"
            );
          } else {
            alert("error");
          }
        });
      }
    },
    timeFromNow() {
      return moment(this.property.createdAt).fromNow();
    },
    changeView(event) {
      this.enquireType = event.target.value;
    },
    openModal() {
      if (!this.check) {
        this.$store.commit("setLoginRequired", true);
        return;
      }
      this.$refs[`modal-enquire${this.property.id}`].show();
      if (this.enquireType == "viewing")
        setTimeout(() => {
          this.property.timeSlots.forEach((slot) => {
            let isViewingSlot = this.property.viewing.some(
              (view) =>
                view.sellerTimeSlotId == slot.id && view.status == "accept"
            );
            if (isViewingSlot) return;
          });
        }, 100);
    },
    makeOffer() {
      if(this.isUserBlocked || this.amIBlocked)
          return true
      this.$store.commit("setShowLoader", true);
      const data = {
        propertyId: this.property.id,
        offerType: this.offerType,
        offerAmount: parseInt(this.offerAmount.replace(/,/g, "")),
        offerId: null,
      };
      this.$store.dispatch("createOffer", data).then((res) => {
        this.$store.commit("setShowLoader", false);
        if (res == true) {
          this.$refs[`modal-enquire${this.property.id}`].hide();
          this.offerAmount = "";
          this.$store.commit(
            "setMessage",
            "Offer made successfully!"
          );
        } else {
          alert("offer was not made");
        }
      });
    },
    getIndex(el) {
      this.$nextTick(() => {
        let elements = el.querySelectorAll("li");
        elements.forEach((elem) => {
          elem.classList.forEach((el) => {
            if (el.includes("flipster__item--current")) {
              this.index = parseInt(elem.id);
            }
          });
        });
        this.currentIndex = this.index || 0;
      });
    },
    counterInit(val) {
      this.getIndex(
        document.querySelectorAll(`.flipsterUpdate${this.property.id}`)[val]
      );
      let selector = (tag) => {
        return document
          .querySelectorAll(`.flipsterUpdate${this.property.id}`)
          ?.forEach((el) => {
            let elements = el.querySelectorAll("li");
            elements.forEach((elem) => {
              elem?.addEventListener(
              "touchstart",
              () => {
                setTimeout(() => {
                  this.getIndex(el);
                }, 400)
              }
            );
              elem?.addEventListener(
              "touchend",
              () => {
                 setTimeout(() => {
                this.getIndex(el);
                 }, 400)
              }
            );
              this.$nextTick(() => {
              document.addEventListener("keydown", event => {
                 event.stopPropagation(); 
                if(event.keyCode == 37 || event.keyCode == 39 && val == 1)
                setTimeout(() => {
                  this.getIndex(el)
                  }, 500)
              }, false)
              })
            elem?.addEventListener(
              "click",
              () => {
                this.getIndex(el);
              }
            );
        })
            el.querySelector(`.flipster__button--${tag}`)?.addEventListener(
              "click",
              () => {
                this.getIndex(el);
              }
            );
          });
      };
      let condition = "i == 0 ? 'prev' : 'next'";
      for (let i = 0; i < 2; i++) {
        selector(eval(condition));
      }
    },
    onMessageSubmit: function (message) {
      const data = {
        propertyId: this.property.id,
        message: message.content,
        messageId: null,
      };
       if(this.isUserBlocked || this.amIBlocked)
          return true
      this.$store.dispatch("createMessage", data).then((res) => {
        if (res == true) {
          this.$store
            .dispatch("buyerPropertyMessages", this.property.id)
            .then((res) => {
              if (res.data.success) {
                this.messages = res.data.data;
              }
            });
        } else {
          alert("message not sent");
        }
      });
    },
    onType() {},
    onClose() {},
    sendMessage() {
      if(this.isUserBlocked || this.amIBlocked)
          return true
      this.$store.commit("setShowLoader", true);
      const data = {
        propertyId: this.property.id,
        message: this.messageBody,
        subject: this.subject,
        messageId: null,
      };
      this.$store.dispatch("createMessage", data).then((res) => {
        this.$store.commit("setShowLoader", false);
        if (res == true) {
          this.$refs[`modal-enquire${this.property.id}`].hide();
          this.$store.commit(
            "setMessage",
            "Message sent successfully!"
          );
          this.messageBody = "";
        } else {
          alert("message was not sent");
        }
      });
    },
  },
  async mounted() {
    this.$nextTick(() => {
      document.querySelector("#modal-1___BV_modal_body_")
        ? (document.querySelector("#modal-1___BV_modal_body_").style.maxHeight =
            this.screenHeight)
        : "";
      document.querySelector("#modal-1___BV_modal_body_")
        ? (document.querySelector("#modal-1___BV_modal_body_").style.maxWidth =
            this.screenWidth)
        : "";
      document.querySelector(".modal-xl")
        ? (document.querySelector(".modal-xl").style.maxWidth =
            this.screenWidth)
        : "";
    });
    this.counterInit(0);
    this.$store
              .dispatch("getPropertyMessages", this.property.id)
              .then((res) => {
                if (res[1].data.success) {
                  this.$emit('componentMessages', { messages: res[1].data.data, property: this.property })
                }
              });
    this.$store
              .dispatch("getPropertyOffers", this.property.id)
              .then((res) => {
                if (res.success) {
                  this.propertyOffers = res.data
                  this.$emit('componentOffers', { offers: res.data, property: this.property })
                }
              });
    this.timeSlots = this.property.timeSlots;
    if (this.observer) {
      let el = this.$refs[`propertyNo${this.property.id}`];
      this.observer.observe(el);
    }
  },
};
</script>

<style scoped>
@import url("../assets/css/fontawesome-all.css");
@import url("../assets/css/fontawesome-5-all.min.css");
@import url("../assets/css/font-awesome.min.css");
@import url("../assets/css/styles.css");

div >>> .material-design-icon__svg {
    width: 30px;
    height: 30px;
    fill: #548f4d;
    stroke: #548f4d;
    cursor: pointer;
    background-color: rgba(black,.2);
    border-radius: 50%;
    opacity: .9;
}


  .lowerbed-icons{
    width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
  }

.priceicon{
  position: absolute;
    left: 15px;
    top: 45px;
      width: 25px;
    text-align: center;
    font-style: normal;
}

.fatimes{
  position: absolute;
    z-index: 90;
    
       left: 69.5%;
    top: 13px;
    font-size: 21px;
}

.margin-r-5{
  margin-right: 5px;
}

.value-enquire{
 font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-weight: 400;
}

@media (min-width: 300px) and (max-width: 700px) {
  .for-display{
    display:none !important;
  }

  .res-mobile-change{
    width:none !important;
    display: flex;
    /* flex-direction: row; */
    justify-content: none !important;
    align-items: baseline;
    /* flex-wrap: wrap; */
    /* align-content: stretch; */
    justify-content: center;
  }

  .res-margin-20{
    margin-right:40px !important;
  }

  .resss-margin-top-0{
    margin-top: 0px !important;
  }

   .res-pl-0-pr-0{
    padding-left: 0px !important;
    padding-right:0px !important;
  }
    .priceicon {
    position: absolute;
    display: block;
    transform: translate(0, -50%);
    top: 53% !important;
    pointer-events: none;
    width: 25px;
    left:0px;
    text-align: center;
    font-style: normal;
  }
  .res-h177 {
    height: 177px !important;
  }
  .res-mb-13{
    margin-bottom: 13px !important;
  }

.res-mt-1rem{
  margin-top: 1rem !important;
}

.res-mt-12{
  margin-top: 13px !important;
}

  .res-full-width{
    width:100% !important;
  }

  .res-bloc-popup{
    
    top: 12% !important;
width: 31% !important;
left: 57% !important;
  }

  
  div >>> .icon-send-message{
    margin:0px !important;
     left: 85% !important;
  }

  div >>> [id*="deleteDraft"]{
    display: none !important;
  }

  div >>> [id*="deleteListing"]{
    display: none !important;
  }

  div >>> [id*="deleteModal"]{
    display: none !important;
  }

   div >>> [id*="relist"]{
    display: none !important;
  }

   div >>> [id*="reject-modal"]{
    display: none !important;
  }

   div >>> [id*="accept-modal"]{
    display: none !important;
  }

  div >>> .modal-content{
    width:100% !important;
    height: calc(100vh - 3rem) !important;
  }

  div >>> .quick-chat-container {
    height: calc(100vh - 23rem) !important;
}

  .res-simple-list-property{
    padding: 0 !important;
    margin-right: -15px !important;
  }
  .res-radious{
        border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .res-font{
    font-size: 1.7rem !important;
  }
  .res-hide{
    display:none !important;
  }
  .res-remove-border-hide {
    border-radius: 0px !important;
  }
  .fatimes{
    left: 90.5% !important;
    top: 9px !important;
  }
  .res-listing-m-0 {
    margin-right: 2px !important;
  }

  .res-t-center {
    text-align: -webkit-center !important;
  }
  .res-remove-border-left {
    border-radius: 0 0 0 0px !important;
  }
  .res-height0 {
     /* height: 495px !important; */
     height:100% !important;
  }
  .res-bg-greenborder{
    border-top-right-radius: 0px !important;
  }
  .res-mr-85{
    margin-top:85px !important;
  }
  .res-mr-35{
    margin-top:35px !important;
  }
  .max {
    max-width: 314px !important;
  }
  .res-h10{
    height: 115px !important;
  }
  .m5 {
    margin-top: 85px !important;
  }
  .magnifer {
    position: absolute;
    top: 93% !important;
    left: 94% !important;
    font-size: 20px;
    color: rgb(39, 43, 50);
  }
  .res-w30{
    width:25px !important;
    height:25px !important;
  }
  .res-float {
    float: none !important;
    padding-bottom: 18px !important;
  }
   .res-float-add {
    float: none !important;
    padding-bottom: 38px !important;
  }
  

  .res-icon-width {
    width: 10% !important;
    float: right !important;
  }

  .res-p-list {
    float: right !important;
  }
}

div >>> .modal-xl {
  max-width: 4000px !important;
}

@media (min-width: 400px) and (max-width: 700px) {
  .max {
    max-width: 360px !important;
  }
  .m5 {
    margin-top: 85px !important;
  }
}

div >>> .vuecal__menu,
.vuecal__cell-events-count {
  background-color: #548f4d;
}

div >>> .modal-backdrop {
  opacity: 0.7;
}

@media (min-width: 768px) {
  .offset-md-1 {
    margin-left: 1%;
  }

}

@media only screen and (max-device-width: 480px) {
  .length-set-description {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.length-set-description {
  flex: 0 0 57%;
  max-width: 56%;
}

div >>> #modal-1___BV_modal_content_ {
  background-color: rgba(255, 255, 255, 0);
  border: none;
}
.project-single >>> .flipster {
  width: 100%;
}
.project-single >>> img {
  width: 250px;
  height: 255px;
}
div >>> .marked-cell {
  background-color: yellow;
}
.stroke-transparent {
  -webkit-text-stroke: 1px #000;
  -webkit-text-fill-color: transparent;
}

.stroke-transparent-premium {
  -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: transparent;
}

i.hover:hover {
  color: #55904e !important;
  -webkit-text-stroke: 1px #55904e;
  -webkit-text-fill-color: #55904e;
}

i.hover-premium:hover {
  cursor: pointer;
  color: #55904e !important;
  -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: white;
}

div >>> .message-text-box {
  padding: 5px !important;
  border-radius: 8px;
   border-top-right-radius:0px !important;
    border-top-left-radius: 0px !important;
  background-color: #f7f3f3;
  border: 1px solid rgb(221, 221, 221) !important;
}

div >>> .container-message-manager {
  padding: 0;
  /* position: absolute;
  left:92%; */
}

div >>> .icon-send-message {
  padding-right: 0 !important;
  position: absolute;
  left: 89%;
  padding: 0px !important;
}

.yellowg {
  border: 0px !important;
  border-radius: 8px !important;
  font-size: 15px !important;
  background-color: #272b32;
  box-shadow: none !important;
  color: #ffffff !important;
  height: 50px !important;
  width: 170px !important;
  transition: all 0.3s ease !important;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgb(0 122 54 / 50%);
}
.ui-elements .button-tabs .tab-title{
  background:none;
}
/* .ui-elements .button-tabs .tab-title{
 background: #B2C9AB !important;
}  */
 .ui-elements .button-tabs .tabs{
  background: #B2C9AB !important;
}

.ui-elements .button-tabs .active .tab-title{
background-color: #658C59;
}
.ui-elements .button-tabs .active .tab-title:hover {
    background: #658C59;
}

div >>> .quick-chat-container{
  height: calc(100vh - 19rem);
}
div >>> .modal-body{
      overflow: hidden;
}
div >>> .modal-header{
  padding-bottom: 0px;
}

.p25{
  padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
}

.plr25{
  padding-left: 25px;
    padding-right: 25px;
}
.bg-green{
  border-top-right-radius: 8px;
background : #548f4d;
}

.c-white{
  color: white !important;
}

.w-30 {
  width: 30px;
  height: 30px;
}


@media (min-width: 800px) and (max-width: 30000px) {

.for-mobile{
  display:none !important;
}

.res-margin-test{
  margin-top: -36px !important;
}

.hide-for-big{
  display:none !important;
 
}
}


.share-green-fill:hover {
  content: url("../assets/images/icons/share-icon-filled.webp");
}

.heart-green-fill:hover {
  content: url("../assets/images/icons/Vencasa-Heat-Filled.webp");
}

.white-heart:hover{
   content: url("../assets/images/icons/heart-white-Icon.webp");
}

.white-share:hover{
   content: url("../assets/images/icons/share-icon-white.webp");
}



.share-white-filled:hover{
   content: url("../assets/images/icons/share-white-filled.webp");
}

.heart-white-filled:hover{
   content: url("../assets/images/icons/heart-white-filled.webp");
}



</style>