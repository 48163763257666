<template>
  <div class="container mb-3">
    <b-table
      @row-clicked="selectUser"
      :fields="fields"
      striped
      hover
      :items="items"
      show-empty
    >
      <template #cell(status)="row">
        <div class="row mr-1">
          <p class="col-md-6">
            {{
              row.item.status == "offer"
                ? "Pending"
                : row.item.status == "accept"
                ? "Accepted"
                : row.item.status == "reject"
                ? "Rejected"
                : (row.item.status == "counter") & fromBuying
                ? "Counter Offer"
                : "Countered"
            }}
          </p>
          <div
            v-if="
              (row.item.status == 'offer' && !fromBuying) ||
              (row.item.status == 'counter' && fromBuying)
            "
            class="d-flex col-md-6"
            style="justify-content: flex-end"
          >
            <button
              @click="selectOffer(row.item, 'accept')"
              class="btn btn-primary listing-button-color res-button-how"
            >
              Accept
            </button>
            <button
              @click="selectOffer(row.item, 'counter')"
              class="btn btn-primary ml-2 listing-button-color res-button-how"
            >
              Counter
            </button>
            <button
              @click="selectOffer(row.item, 'reject')"
              class="btn btn-primary ml-2 listing-button-color res-button-how"
            >
              Reject
            </button>
          </div>
          <button
            @click="selectOffer(row.item, 'counter')"
            v-show="row.item.status == 'reject'"
            class="btn btn-primary listing-button-color col-md-6 res-button-how"
          >
            Update Offer
          </button>
        </div>
      </template>
      <template #cell(offer)="row">
        <p>£ {{ formatter(row.item.offerAmount) }}</p>
      </template>
      <template #cell(offerType)="row">
        {{ capitalize(row.item.offerType) }}
      </template>
      <template #cell(buyer)="row">
        {{ getUserName(row.item.userId) }}
      </template>
      <template #cell(offerDate)="row">
        {{ formatDate(row.item.offerDate) }}
      </template>
      <template #empty>
        <div class="d-flex justify-content-center align-items-center">
          <p>No Offers</p>
        </div>
      </template>
    </b-table>
    <b-modal :ref="`counter-modal-${property.id}`" centered title="Counter Offer" id="my-modal">
      <div class="row">
        <div class="container">
        <p><strong>{{currentUser}}</strong> has offered <strong style="color: rgb(101, 140, 89);">£ {{ formatter( currentOffer.offerAmount) }}</strong>.</p>
        <p>You can make a counter offer including the amount you would like to offer.</p>
        <p>If accepted, this property will be marked as sold and will no longer appear in the search results.</p>
        <div class="row">
        <div class="col-md-6">
          <p class="m-0">Value</p>
           <input class="custome_input"
          @input="addCommas($event)"
          :value="counterOfferAmount"
          type="text"
          placeholder="Enter counter amount"
        />
          </div>
          <div class="col-md-6">
            <p class="m-0">Type</p>
            <select :disabled="counterOfferType" v-model="counterOfferType" class="custome_input" name="" id="">
          <option
            v-for="(item, index) in offerTypes"
            :key="index"
            :value="item.toLowerCase()"
          >
            {{ item }}
          </option>
        </select>
            </div>
            </div>
            </div>
       
        
        <!-- <p class="mt-2">A message with your counter offer will be sent</p> -->
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button style="    width: 217px;
    background-color: rgb(89, 89, 89);
    color: white;"
            @click="$refs['my-modal'].hide()"
            variant="secondary"
           
            class="float-left listing-button-color-secondary"
          >
            Cancel
          </b-button>
          <b-button style="    width: 217px;
    background-color: rgb(89, 89, 89);
    color: white;"
            @click="sendCounterOffer"
            variant="primary"
            
            class="float-right listing-button-color"
          >
            Send
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal @shown="setHeightReject(property.id)" :ref="`reject-modal-${property.id}`" centered title="Reject" id="my-modal1">
      <div class="d-flex flex-column align-items-center">
        <p>
          Are you sure you wish to reject <strong>{{ currentUser }}</strong
          >'s Offer of:
        </p>
        <strong
          ><p>£ {{ currentOffer.offerAmount }}</p></strong
        >
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            @click="$refs['my-modal1'].hide()"
            variant="secondary"
            size="sm"
            class="float-left listing-button-color-secondary"
          >
            No
          </b-button>
          <b-button
            @click="rejectOffer()"
            variant="primary"
            size="sm"
            class="float-right listing-button-color"
          >
            Yes
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal @shown="setHeightAccept(property.id)" :ref="`accept-modal-${property.id}`" centered title="Accept Offer" id="my-modal2">
      <div v-if="!fromBuying" class="d-flex flex-column">
        <p>
          Are you sure you wish to accept <strong>{{ currentUser }}</strong
          >'s Offer of:
          <strong style="color: rgb(101, 140, 89)"
            >£ {{ formatter(currentOffer.offerAmount) }}</strong
          >
        </p>
        <p>
          Your property will be marked as sold and will no longer appear in the
          search results.
        </p>
        <p>
          Once you have accepted this offer, you can exchange your solicitor
          details with <strong>{{ currentUser }}</strong> to proceed with the sale.
        </p>
      </div>
      <div v-else class="d-flex flex-column">
        <p>
          Are you sure you wish to accept <strong>{{ currentUser }}</strong
          >'s Offer of:
          <strong style="color: rgb(101, 140, 89)"
            >£ {{ formatter(currentOffer.offerAmount) }}</strong
          >
        </p>
        <p>
          Once accepted you will be asked to share your solicitors details with {{ currentUser }}.
        </p>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button style="    width: 200px;
    background-color: rgb(89, 89, 89);
    color: white;"
            @click="$refs['my-modal2'].hide()"
            variant="secondary"
            class="float-left listing-button-color-secondary"
          >
            No
          </b-button>
          <b-button style="    width: 200px;
    background-color: rgb(89, 89, 89);
    color: white;"
            @click="acceptOffer()"
            variant="primary"
            class="float-right listing-button-color"
          >
            Yes
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { formatter } from "../utils";
import { capitalize } from "../utils";
import _ from "lodash";

export default {
  props: ["offers", "fromBuying", "property"],
  data() {
    return {
      offerTypes: [],
      currentUser: "",
      username: {},
      currentOffer: {},
      counterOfferAmount: "",
      counterOfferType: "",
      items: [],
      fields: this.fromBuying
        ? ["offerType", "offer", {
          key: "offerDate", label: "Offer Date", tdClass: 'display-to-none', thClass: 'display-to-none'
        }, "offer", {
          key: "status", label: "status", tdClass: 'display-to-none', thClass: 'display-to-none'
        }]
        : ["buyer", "offerType",  {
          key: "offerDate", label: "Offer Date", tdClass: 'display-to-none', thClass: 'display-to-none'
        }, "offer", {
          key: "status", label: "status", tdClass: 'display-to-none', thClass: 'display-to-none'
        }],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth",
    }),
  },
  methods: {
    setHeightReject(propertyId){
      document
        .querySelector(`#reject-modal-${propertyId}___BV_modal_content_`)
        .setAttribute(
          "style",
          "height : auto !important; display: block !important"
        );
      document
        .querySelector("#reject-modal-" + propertyId)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#reject-modal-${propertyId}___BV_modal_body_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#reject-modal-${propertyId}___BV_modal_backdrop_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#reject-modal-${propertyId}___BV_modal_outer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#reject-modal-${propertyId}___BV_modal_header_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#reject-modal-${propertyId}___BV_modal_footer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#reject-modal-${propertyId}___BV_modal_title_`)
        .setAttribute("style", "display: block !important");

    },
    setHeightAccept(propertyId){
      document
        .querySelector(`#accept-modal-${propertyId}___BV_modal_content_`)
        .setAttribute(
          "style",
          "height : auto !important; display: block !important"
        );
      document
        .querySelector("#accept-modal-" + propertyId)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#accept-modal-${propertyId}___BV_modal_body_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#accept-modal-${propertyId}___BV_modal_backdrop_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#accept-modal-${propertyId}___BV_modal_outer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#accept-modal-${propertyId}___BV_modal_header_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#accept-modal-${propertyId}___BV_modal_footer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#accept-modal-${propertyId}___BV_modal_title_`)
        .setAttribute("style", "display: block !important");
      
    },
    capitalize: capitalize,
    formatter: formatter,
    addCommas(event) {
      this.counterOfferAmount = this.formatter(
        event.target.value.toString().replace(/,/g, "")
      );
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    selectUser(userId) {
      this.currentUser = this.getUserName(userId);
    },
    getUserName(id) {
      let keys = Object.keys(this.username);
      if (!keys.includes(id.toString())) {
        this.$store.dispatch("getBuyerSellerForBlog", id).then((res) => {
          if (res) {
            console.log(res, "seller name")
            this.$set(this.username, id, res.data.data.name);
            this.selectUser(id)
          }
        });
      }
      return this.username[id];
    },
    acceptOffer() {
      this.$store
        .dispatch("acceptOffer", this.currentOffer.id)
        .then((res) => {
          if (res == true) {
            this.$refs[`accept-modal-${this.property.id}`].hide()
            this.$store.commit("setMessage", "Offer Accepted");
            this.$store.dispatch("changeOfferReadStatus", {
              id: this.currentOffer.id,
              data: { isRead: 0 },
            });
            this.$store
              .dispatch("getPropertyOffers", this.property.id)
              .then((res) => {
                if (res.success) {
                  this.$emit("componentOffers", res.data);
                }
              });
            this.$store.dispatch(
              "getBuyerSeller",
              this.authUser.buyer_seller_id
            );
            this.$router.push(`/accepted-offer/${this.property.id}/${this.currentOffer.userId}`).catch(() => {});
          } else alert("error");
        });
    },
    rejectOffer() {
      this.$store
        .dispatch("rejectOffer", this.currentOffer.id)
        .then((res) => {
          if (res == true) {
            this.$refs[`reject-modal-${this.property.id}`].hide()
            this.$store.commit("setMessage", "Offer Rejected");
            this.$store.dispatch("changeOfferReadStatus", {
              id: this.currentOffer.id,
              data: { isRead: 0 },
            });
            this.$store
              .dispatch("getPropertyOffers", this.property.id)
              .then((res) => {
                if (res.success) {
                  this.$emit("componentOffers", res.data);
                }
              });
            this.$store.dispatch(
              "getBuyerSeller",
              this.authUser.buyer_seller_id
            );
            this.$refs["my-modal1"].hide();
            this.$store.commit("setMessage", "Offer rejected!");
          } else alert("error");
        });
    },
    selectOffer(offer, label) {
      this.selectUser(offer.userId);
      this.currentOffer = offer;
      this.counterOfferType = _.get(offer, 'offerType', '');
      this.$refs[`${label}-modal-${this.property.id}`].show()
    },
    sendCounterOffer() {
      const data = {
        propertyId: this.currentOffer.propertyId,
        offerType: this.counterOfferType,
        offerAmount: parseInt(this.counterOfferAmount.replace(/,/g, "")),
        status: this.fromBuying ? "offer" : "counter",
      };
      this.$store
        .dispatch("updateOffer", { data: data, id: this.currentOffer.id })
        .then((res) => {
          this.$store.dispatch("changeOfferReadStatus", {
            id: this.currentOffer.id,
            data: { isRead: 0 },
          });
          this.$store
            .dispatch("getPropertyOffers", this.property.id)
            .then((res) => {
              if (res.success) {
                this.$emit("componentOffers", res.data);
              }
            });
          if (res == true) {
            this.$store.dispatch(
              "getBuyerSeller",
              this.authUser.buyer_seller_id
            );
            this.$refs[`counter-modal-${this.property.id}`].hide()
            this.$store.commit(
              "setMessage",
              "Counter offer sent!"
            );
          } else alert("counter offer not sent");
        });
    },
  },
  async mounted() {
    this.items = this.offers.filter((offer) => {
      return this.fromBuying
        ? offer.userId == this.authUser.buyer_seller_id
        : offer.userId != this.authUser.buyer_seller_id;
    });
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].isRead == 0 && this.items[i].lastOfferBy !== this.authUser.buyer_seller_id)
        await this.$store.dispatch("changeOfferReadStatus", {
          id: this.items[i].id,
          data: { isRead: 1 },
        });
    }
    this.$store
      .dispatch("getPropertyOffers", this.property.id)
      .then((res) => {
        if (res.success) {
          this.$emit("componentOffers", res.data);
        }
      });
    if (!this.offerTypes.length)
      this.$store
        .dispatch("getConfigurationsByKey", "offerType")
        .then((res) => {
          if (res.data.success) {
            this.offerTypes = Object.keys(res.data.data.val);
          }
        });
  },
};
</script>

<style scoped>
@import url("../assets/css/styles.css");


@media (min-width: 300px) and (max-width: 700px) {
  div >>> .display-to-none{
  display: none;
}
}



div >>> .modal-content {
  border-radius: 8px;
}

div >>> .modal-header {
  border-bottom: 0px;
}

div >>> modal-body{
padding-bottom: 0px !important;
}

div >>> .modal-footer{
  border-top: 0px;
}

.custome_input{
      background: #f5f5f5;
    border: none;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    font-weight: 500;
    margin-bottom: 24px;
    border-radius: 0;
}
.ui-h4{
  letter-spacing: 2px;
    margin-right: -2px;
    font-size: 18px;
    color: #444;
    font-weight: 400;
    margin-bottom: 24px;
}
</style>